// store.js
import create from 'zustand';
import { UserAuth, AuthResponse } from '../types/auths-types';

const useAuthStore = create((set) => ({
  user: { ...UserAuth },
  response: { ...AuthResponse },
  setUser: (user) => set({ user }),
  setResponse: (response) => set({ response }),
}));

export default useAuthStore;
