import React,{useState,useEffect} from 'react'
import { blogService } from '../APIs/services/blog';

import {Link} from 'react-router-dom'
import './BlogDetails.css'



const BlogDetailsMight = () => {
    const [allBlogs, setAllBlogs] =useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            const data = await blogService.getBlogs();
            setAllBlogs(data);
            setLoading(false);
          } catch (error) {
            console.error('error fetching blogs:', error);
          }
        };
    
        fetchBlogs();
      }, []);


  return (
    <div className="news-container">
<div className='like-others'>
  <h1>You might also like </h1>
</div>
<div className='blog-news-card-container'>

{loading ? (
  <p>Loading...</p>
) : (
  allBlogs.map((blog) => (

    <div className='blog-news-card' >
     
     
      <img
        src={blog.image}
        alt='Blog'
        className='blog-news-card-image'
      />
      <div className='blog-news-card-content'>
        <button className='news-world-epilepsy'>{blog.date}</button>
        <h2 className='news-blog-card-title'>{blog.title}</h2>
        <p className='news-blog-card-description'>{blog.description}</p>
      </div>
      <hr />
      <div className='blog-readmore-btn'>
        <Link to= {`/BlogDetails/${blog._id}`} className="blog-news-card-button" >
          Read More
        </Link>
      </div>
    
    </div>
  ))
)}
</div>
</div>
  )
}

export default BlogDetailsMight
