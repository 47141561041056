import React from 'react'
import './EmergencyCall.css'
import Calls from '../../Assets/emergencys-transformed.png'
import { Ri24HoursFill } from "react-icons/ri";
import { GiClockwork } from "react-icons/gi";
import { PiPhoneCallDuotone } from "react-icons/pi";

function EmergencyCall() {
  return (
    <div className='emergency-call' >
      <div className="calls">

        <div className='emergency-calls-medicare'>
        <h1 className='medical-cares-hours'>Emergency <br/>Medical Care 24/7 hours </h1>
       
        <p>With access to 24-hour emergency assistance at 
          Federal Neuropsychiatric Hospital Dawanau, Kano, 
          it's paramount to us that you receive the necessary 
          help when you need it. Please call the number below
           for immediate assistance</p>
           <h2 className='title-emegencies'>
           <button className='call-icons-emergency'>
            <PiPhoneCallDuotone className='button-icon-emergency-call' />
            </button> +2348105226705<span style={{marginLeft:'15px', marginRight:'10px',color:'#01A85A'}}>
              Or</span> +2348133358989</h2> 
        </div>
        <div className='call-this-number-for-help'>
        <img src={Calls} alt='' className='emergency-number-call'style={{ animation: 'dance 3s infinite alternate' }}/>
      </div>
      </div>

     
    </div>
  )
}

export default EmergencyCall
