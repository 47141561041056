import React, { useState, useEffect } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import './News.css';
import { Link } from 'react-router-dom';

import { blogService } from '../APIs/services/blog';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar'
import Footer from '../component/Footer/Footer';


const News= () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
 

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const data = await blogService.getBlogs();
        setBlogs(data);
        setLoading(false);
      } catch (error) {
        console.error('error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);


  return (
   
    <>
      <Header />
      <Navbar />
      <div className="news-articles-header">
        <div className="news-content-header">
          <h1>News & Articles</h1>
          <p>Exciting news from FNPHD! We're thrilled to share a recent development that promises positive impact. Stay tuned for the latest updates on how this news will enhance our services and benefit our community.</p>
        </div>
      </div>
      <div className="news-container">
        <div className='blog-news-card-container'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            blogs.map((blog) => (
              <div className='blog-news-card' >
                <img
                  src={blog.image}
                  alt='Blog Image'
                  className='blog-news-card-image'
                />
                <div className='blog-news-card-content'>
                  <button className='news-world-epilepsy'>{blog.date}</button>
                  <h2 className='news-blog-card-title'>{blog.title}</h2>
                  <p className='news-blog-card-description'>{blog.description}</p>
                </div>
                <hr />
                <div className='blog-readmore-btn'>
                <Link to={`/BlogDetails/${blog._id}`}className="blog-news-card-button">
  Read More <FaLongArrowAltRight />
</Link>
                </div>
              </div>
            ))
          )}
        </div>
       
      </div>

      
      <div>
     
      </div>
      <Footer />
    </>
   
  );
};

export default News;
