import React, { useState, useEffect } from 'react';
import { useSpring, useTrail, useTransition, animated } from 'react-spring';
import Header from './component/Header/Header';
import Home from './component/Home/Home';
import Navbar from './component/NavBar/Navbar';
import LiveNews from './component/newsUpdate/LiveNews';
import About from './component/About-us/About';
import Consultation from './component/Consultation/Consultation';
import Services from './component/services/Services';
import BlogNews from './component/BlogNews/BlogNews';
import Footer from './component/Footer/Footer';
import EmergencyForm from './component/Forms/EmergencyForm';
import NewsLetter from './Pages/NewsLetter';
import WhyUs from './Pages/Why';
import Loading from './component/ui/InitialAppLoader';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate loading delay for demonstration purpose
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as per your requirement
  }, []);

  const fadeIn = useSpring({
    opacity: loading ? 0 : 1,
    from: { opacity: 0 },
    delay: 1500, // Adjust as needed
  });

  const slideInTrail = useTrail(1, {
    opacity: loading ? 0 : 1,
    transform: loading ? 'translateY(100px)' : 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(100px)' },
    delay: 1500, // Adjust as needed
  });

  const flipInTransition = useTransition(!loading, {
    from: { opacity: 0, transform: 'rotateX(90deg)' },
    enter: { opacity: 1, transform: 'rotateX(0deg)' },
    leave: { opacity: 0, transform: 'rotateX(90deg)' },
    delay: 1500, // Adjust as needed
  });

  const gradientBackground = {
    background: 'linear-gradient(90deg, rgba(0, 168, 83, 0.32) -89.5%, rgba(251, 218, 90, 0.28) 46.1%, rgba(34, 136, 169, 0.32) 186.5%)',
    height: '130vh', // You can adjust the height based on your design
  };

  return (
    <div style={gradientBackground}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header style={fadeIn} />
          <Navbar style={fadeIn} />
          <div>
            <animated.div style={slideInTrail[0]}>
              <Home />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <LiveNews />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <WhyUs />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <About />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <Consultation />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <NewsLetter />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <Services />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <BlogNews />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <EmergencyForm />
            </animated.div>
            <animated.div style={slideInTrail[0]}>
              <Footer />
            </animated.div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
