import React, { useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Footer from '../component/Footer/Footer';
import ClinicalServices from '../Assets/howitworks.svg'
import './Clinical.css';
import NewsLetter from './NewsLetter';
import Faq from './Faq';

// Define your SVG component
const CircleIcon = () => (
  <div
    style={{
      width: '20px', // Adjust size as needed
      height: '20px', // Adjust size as needed
      borderRadius: '50%', // Makes it circular
      backgroundColor: '#4CAF50', // Green color
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FFFFFF" // White color
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M21 10.08V11C20.9988 13.1564 20.3005 15.2547 19.0093 16.9818C17.7182 18.709 15.9033 19.9725 13.8354 20.5839C11.7674 21.1953 9.55726 21.1219 7.53447 20.3746C5.51168 19.6273 3.78465 18.2461 2.61096 16.4371C1.43727 14.628 0.879791 12.4881 1.02168 10.3363C1.16356 8.18455 1.99721 6.13631 3.39828 4.49706C4.79935 2.85781 6.69279 1.71537 8.79619 1.24013C10.8996 0.764896 13.1003 0.982323 15.07 1.85999M21 2.99999L11 13.01L8.00001 10.01"
      />
    </svg>
  </div>
);

function Clinical() {
  const [showNewsletter, setShowNewsletter] = React.useState(false);
  const [showFaq, setShowFaq] = React.useState(false);
  const ref = useRef();
  const newsletterProps = useSpring({
    opacity: showNewsletter ? 1 : 0,
    transform: showNewsletter ? 'translateX(0)' : 'translateX(-100%)',
  });
  const faqProps = useSpring({
    opacity: showFaq ? 1 : 0,
    transform: showFaq ? 'translateX(0)' : 'translateX(-100%)',
  });
  const handleScroll = () => {
    const element = ref.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top < window.innerHeight && !showNewsletter) {
        setShowNewsletter(true);
      }
      if (rect.top < window.innerHeight && !showFaq) {
        setShowFaq(true);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const AnimatedCard = ({ children }) => {
    const props = useSpring({
      opacity: 1,
      transform: 'translateX(0)',
      from: { opacity: 0, transform: 'translateX(-100%)' },
    });
    return <animated.div style={props}>{children}</animated.div>;
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="about-component" >
        <div className="admin-content">
          <h1 >Clinical Service Department</h1>
        </div>
      </div>
      <div className='clinical-department-header'>
        <h1>Clinical Service Department</h1>
        <p>This department is divided into the following sections and units respectively</p>
      </div>
      <div className="container-clinical-service">

        <div className="card-clinical-services top-left">
          <h1>Medical Services</h1>
          <ul className="service-clinical-grid">
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>  General Psychiatry</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Addiction psychiatry</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Child and adolescent</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Geriatric psychiatric</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Biological psychiatry</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Psychotherapy</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Forensic psychiatry</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Emergency psychiatry</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />
                <li>Neurology</li>
              </div>
            </AnimatedCard>
            <AnimatedCard>
              <div className='service-circle-clinical'>
                <CircleIcon className="IcrcleIcons-services" />

                <li>Community psychiatry</li>
              </div>
            </AnimatedCard>
          </ul>
        </div>

        <div className="card-clinical-services bottom-left">
          <h1>Nursing Unit</h1>
          <p>
            The Nursing unit provide specialized care for patients with mental health conditions.
          </p>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Quality assurance</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>In-service training</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Compound nursing</li>
            </div>
          </AnimatedCard>
        </div>

        <div className="image-container">
          <img src={ClinicalServices} alt="Centered" className="centered-image" />
        </div>
        <div className="card-clinical-services top-right">
          <h1>
            Laboratory unit
          </h1>
          <p>

            Laboratory unit likely focuses on
            biochemical testing to support
            mental health diagnoses
          </p>

          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Chemical Pathology</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Medical Micro-Biology</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Hematology</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Emergency Side Laboratory</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Molecular Laboratory</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>NHIA Laboratory</li>
            </div>
          </AnimatedCard>

        </div>

        <div className="card-clinical-services bottom-right">
          <h1>
            Pharmacy Unit
          </h1>
          <p>
            The unit serves clinic patients,
            filling prescriptions and dispensing
            medications for nurse administration.
          </p>

          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>
                Outpatient Pharmacy

              </li>
            </div>
          </AnimatedCard>

          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>
                In-patient Pharmacy
              </li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>National Health Insurance Authority</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li> Pharmacy Store</li>
            </div>
          </AnimatedCard>
          <AnimatedCard>
            <div className='service-circle-clinical'>
              <CircleIcon className="IcrcleIcons-services" />
              <li>Quality control</li>
            </div>
          </AnimatedCard>
        </div>
      </div>
      <div ref={ref}>
        <animated.div style={newsletterProps}>
          <NewsLetter />
        </animated.div>
        <animated.div style={faqProps}>
          <Faq />
        </animated.div>
      </div>

      <Footer />
    </>
  );
}

export default Clinical;
