import React from 'react';
import './why.css';
import { FiEye, FiStar } from 'react-icons/fi'; // Import additional icons
import { FaUnity } from "react-icons/fa";
import { LuGoal } from "react-icons/lu";

const HeroCard = ({ front, back, icon }) => { // Add 'icon' prop
  const [isFlipped, setIsFlipped] = React.useState(false);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const renderIcon = () => {
    switch (icon) {
      case 'eye':
        return <FiEye size={24} style={{ color: '#01a85a', marginBottom: '10px' }} />;
      case 'FaUnity':
        return <FaUnity size={24} style={{ color: '#9a8e0c', marginBottom: '10px' }} />;
      case 'LuGoal':
        return <LuGoal size={24} style={{ color: '#01a85a', marginBottom: '10px' }} />;
      default:
        return null;
    }
  };

  return (
    <div className={`hero-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardFlip}>
      <div className="Hero-card-content front">
        <div className={`circle ${icon}`}>
          {renderIcon()} {/* Render the appropriate icon */}
        </div>
        <h3>{front}</h3>
      </div>
      <div className="Hero-card-content back">
        <div className={`circle back ${icon}`}></div>
        <p>{back}</p>
      </div>
    </div>
  );
};

const Cards = () => {
  return (
    <div className='HomeCards'>
      <h1 style={{ textAlign: 'center', marginTop: '4%', position: 'relative' }}>
      What would you like to know about us?
        <div className="underline"></div>
        <br/>
        
      </h1>
     
     
      <div className="Hero-grid-container">
        <HeroCard
          front="Online Payments  ?"
          back="Payment at Federal Neuropsychiatric Hospital Dawanau Kano can now be conveniently made using the Remita platform."
          icon="eye" // Specify the icon for this card
        />
        <HeroCard
          front="
          Outpatient/Inpatient consultant & Clinic Services ?"
          back="
          
         FNHD Kano provides comprehensive
           healthcare services beyond mental health treatment. From
            outpatient consultations to specialized clinic services, 
            state-of-the-art diagnostics to rehabilitation.
          "
          icon="FaUnity" // Specify the icon for this card
        />
        <HeroCard
          front="24 Hours Emergency service"
          back="Our unwavering commitment to 24-hour emergency 
          response remains our cornerstone value, regardless of
           the severity of the situation, at the Federal 
           Neuropsychiatric Hospital Dawanau Kano."
          icon="LuGoal" // Specify the icon for this card
        />
      </div>
    </div>
  );
};

export default Cards;