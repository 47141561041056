import React, { useState, useEffect } from 'react';
import './LiveNews.css';

const Marquee = () => {
  const [position, setPosition] = useState(0);
  const marqueeRef = React.createRef();

  useEffect(() => {
    const marqueeWidth = marqueeRef.current.offsetWidth;
    const textWidth = marqueeRef.current.children[0].offsetWidth;

    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        // If the text is completely outside the marquee, reset position
        if (prevPosition < -textWidth) {
          return marqueeWidth;
        }
        return prevPosition - 2;
      });
    }, 25); // You can adjust the interval based on your preference

    return () => clearInterval(interval);
  }, [marqueeRef]);

  return (
    <div className="marquee" ref={marqueeRef}>
      <div  className='Param' style={{ transform: `translateX(${position}px)`}}>
        THE MANAGEMENT OF FEDERAL NEUROPYCHIATRIC HOSPITAL PAYS A DISTINGUISHED 
        VISIT TO KANO STATE COMMISIONER FOR HEALTH  || 
        THE FEDERAL NEUROPSYCHIATRIC HOSPITAL HAS DRILLED 
        A NEW BOREHOLE TO ACCESS A RELIABLE WATER SOURCE.|| 
        THE FEDERAL NEUROPSYCHIATRIC HOSPITAL DAWANAU IS 
        PRIORITIZING PATIENT WELL-BEING WITH THE CONSTRUCTION 
        OF A MODERN CAFTERIA, ENSURING NUTRITIOUS
         AND COMFORTABLE DAILY MEALS. || 
         THE FEDERAL NEUROPSYCHIATRIC HOSPITAL DAWANAU TAKES A BIG LEAD 
         FORWARD BY RENOVATING THE OLD DILAPIDATED WARDS MAKING 
         IT SEVEN WARDS IN TOTAL, CREATING A MORE MODERN 
         AND POTENTIALLY MORE EFFECTIVE TREATMENT ENVIRONMENT.

           || THE FEDERALL NEUROPSYCHIATRIC HOSPITAL HAS COMPLETED 
           CONTRUCTION ON A NEW ROAD LEADING TO THE 
           FACILITY, IMPORIVING PATIENT ACCESS.
           
      </div>
    </div>
  );
};

export default Marquee