import React, { useRef, useEffect, useState } from 'react';
import './Statistics.css';

export default function Statistics() {
  const observer = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const startCounting = () => {
    if (!isIntersecting) return;

    const counters = document.querySelectorAll('.statCard p');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const target = entry.target;
          const countTo = parseInt(target.innerText, 10);
          let count = 0;
          const animationInterval = setInterval(() => {
            if (count <= countTo) {
              target.innerText = count;
              count++;
            } else {
              clearInterval(animationInterval);
            }
          }, 10);
          observer.unobserve(target);
        }
      });
    };

    observer.current = new IntersectionObserver(callback, options);
    counters.forEach(counter => {
      observer.current.observe(counter);
    });
  };

  useEffect(() => {
    startCounting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop === 0) {
        setIntersecting(false);
      } else {
        setIntersecting(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIntersecting(true); // Trigger animation on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="statistics">
      <div className='Top-Cards'>
        <h1 className="title-stat">Some of Our Numbers</h1>
      </div>
      <div className="StatCards">
        <div className="statCard">
          <p>100 +</p>
          <span>Patients</span>
        </div>
        <div className="statCard">
          <p>200 +</p>
          <span>Staff Members</span>
        </div>
        <div className="statCard">
          <p>90 +</p>
          <span>Patients Recovery</span>
        </div>
        <div className="statCard">
          <p>80 +</p>
          <span>Doctors</span>
        </div>
        <div className="statCard">
          <p>30 +</p>
          <span>Trained Nurses</span>
        </div>
        <div className="statCard">
          <p>20 +</p>
          <span>Years of Experience</span>
        </div>
      </div>
    </div>
  );
}
