import React,{useEffect,useState} from 'react';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Footer from '../component/Footer/Footer'
import './HospitalServices.css';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


// Define your SVG component
const CircleIcon = () => (
  <div
    style={{
      width: '40px', // Adjust size as needed
      height: '40px', // Adjust size as needed
      borderRadius: '50%', // Makes it circular
      backgroundColor: '#4CAF50', // Green color
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FFFFFF" // White color
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M21 10.08V11C20.9988 13.1564 20.3005 15.2547 19.0093 16.9818C17.7182 18.709 15.9033 19.9725 13.8354 20.5839C11.7674 21.1953 9.55726 21.1219 7.53447 20.3746C5.51168 19.6273 3.78465 18.2461 2.61096 16.4371C1.43727 14.628 0.879791 12.4881 1.02168 10.3363C1.16356 8.18455 1.99721 6.13631 3.39828 4.49706C4.79935 2.85781 6.69279 1.71537 8.79619 1.24013C10.8996 0.764896 13.1003 0.982323 15.07 1.85999M21 2.99999L11 13.01L8.00001 10.01"
      />
    </svg>
  </div>
);
function HospitalServices() {

  const [scrollDirection, setScrollDirection] = useState('down');
  
  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const handleScroll = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });
 
  return (
    <>
      <Header />
      <Navbar />

      <div className="services-component" >
        <div className="services-content">
          <h1 >Our Services</h1>
        </div>
      </div>
      
      <div></div>

      <div className='hospital-service-grids'>

      
      <motion.div
          className="Hospital-Services-container"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
      
      
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title">Outpatient Department</div>
          <p className='ConsultationParams'>
          We offer outpatient care 7 days a week at our outpatient unit. We have 
          morning and evening session throughout the week.


          </p>
        </div>
        </motion.div>


        <motion.div
          className="Hospital-Services-container"
          initial={{
            opacity: scrollDirection === 'down' ? 0 : 1,
            y: scrollDirection === 'down' ? -50 : 0
          }}
          animate={{
            opacity: 1,
            y: 0
          }}
          transition={{ duration: 0.5 }}
        >
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Emergency Psychiatric Services</div>
          <p className='ConsultationParams'>
          We offer emergency care 24/7. Condition 
          requiring the care include those associated with 
          sense description and aggression as well as 
          rigid change in behavior.
          </p>
        </div>
        </motion.div>
      <div className="Hospital-Services-container">
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Substance Abuse Treatment and Rehabilitation</div>
          <p className='ConsultationParams'>
          We offer both outpatient and in-patient care to people with 
          substance use problems-Abuse and dependance. 
          These include assessment, Testing, detoxification and rehabilitation.
          </p>
        </div>
      </div>

      <div className="Hospital-Services-container">
  <div className="services">
  <CircleIcon className="IcrcleIcons-services"/>
    <div class="directorate-sub-title"> Psychological Services</div>
    <p class='ConsultationParams'>
      We offer psychological testing service, counseling and psychological therapy.
    </p>
  </div>
</div>


      <div className="Hospital-Services-container">
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Neurodiagnostic and Neurostimulation service</div>
          <p className='ConsultationParams'>
          We offer EEG(Electroencephalogram) 
          test and ECT (Electroconvulsive Therapy) 
          both modified and unmodified for our patients
          </p>
        </div>
      </div>

      <div className="Hospital-Services-container">
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Child and Adolescent Psychiatric Services</div>
          <p className='ConsultationParams'>
          we treat children and young adult with 
          varieties of mental health challenge from general psychiatric conditions 
          to emergency psychiatric service, forensic and substance use problems.
          </p>
        </div>
      </div>

      <div className="Hospital-Services-container" id='forensic'>
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title">Forensic Psychiatric Services</div>
          <p className='ConsultationParams'>
          We offer service to people with mental health 
          challenge who are dealing with legal issues 
          such as those arrested, undergoing trial, 
          convict, prisoners and others.
          </p>
        </div>
      </div>

      <div className="Hospital-Services-container" sectionId="general-psychiatric">
        <div className="services" id="general-psychiatric">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> General Psychiatric Services</div>
          <p className='ConsultationParams'>
          We offer care to the elderly focus on assessment, diagnosis and 
          treatment and rehabilitation. We treat condition 
          such as depression dementia, Insomnia, Hypersomnia,
           Psychosis and others.
          </p>
        </div>
      </div>
     


      <div className="Hospital-Services-container" id='social'>
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title">Social Support Services</div>
          <p className='ConsultationParams'>
          We service to assist patients their families and staff. 
          This focus is to reintegrate patients into their 
          family and community during treatment and rehabilitation.
          </p>
        </div>
      </div>
      <div className="Hospital-Services-container" id='mental-health'>
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Training Research and Quality  Improvement</div>
          <p className='ConsultationParams'>
              We offer training in different aspect of prevention,
              treatment and rehabilitation of variety of mental
              health conditions both for continuing professional
              development of our staff and for others who are willing
              to engage our services.
              We receive students on attachment and posting.
              We undertake research to add scientific knowledge
              regarding mental health conditions

          </p>
        </div>
      </div>
      <div className="Hospital-Services-container">
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Occupational Therapy Services</div>
          <p className='ConsultationParams'>
          We currently offer limited range of services 
          now but hope to increase our range of services by the end 
          of 2024 when we completed a purposely built 
          occupational therapy unit.
          </p>
        </div>
      </div>
     

      <div className="Hospital-Services-container">
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title"> Generiatic Psychiatric Service</div>
          <p className='ConsultationParams'>
          We offer care to the elderly focus on assessment, 
          diagnosis and treatment and rehabilitation. We treat condition 
          such as depression dementia, Insomnia, Hypersomnia,
           Psychosis and others.
          </p>
        </div>
      </div>
     


     
      <div className="Hospital-Services-container">
        <div className="services">
        <CircleIcon className="IcrcleIcons-services"/>
          <div className="directorate-sub-title">Laboratory Service </div>
          <p className='ConsultationParams'>
          We offer a range of diagnostic test for our patients which assist in delivery quality core.
          </p>
        </div>
      </div>
      </div>
      
      <Footer />
    </>
  );
}

export default HospitalServices;