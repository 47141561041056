import React from 'react';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Finance from '../Assets/nice-finace-edit.png'
import Footer from '../component/Footer/Footer'
import { Link } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";


import './Finance.css';
import HeadFinance from './HeadFinance';


function Finances() {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });

  const [rightRef, rightInView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the right-side div is visible
  });
  return (
    < >
      <Header />
      <Navbar />

      <div className="about-component" >
        <div className="admin-content">
          <h1 >Finance Department</h1>
        </div>
      </div>



      <div ref={ref} className="consultation-container">
       

          <div className="left-staff-finance-accounts">
          <div className="staff-sub-title">Finance & Accounts</div>
          <AnimatePresence>
          <motion.div

initial={{ x: "-100vh", opacity: 0 }}
animate={{ x: "0", opacity: inView ? 1 : 0 }}
transition={{ duration: 1.5, delay: 0.1 }}
exit={{ x: "-100vh", opacity: 0 }}
          
          >
          <p className='ConsultationParams'>
          The finance department functions as the crucial backbone of any 
          business, entrusted with the acquisition, allocation, 
          and management of all financial assets and resources 
          on behalf of the organization. 
          </p>
          <p className='ConsultationParams'>
          This pivotal unit not 
          only controls the inflow and outflow of funds but also 
          plays a key role in strategic financial planning, risk
           management, and ensuring compliance with regulatory 
           standards. By meticulously monitoring financial 
           performance and implementing sound fiscal policies, 
           the finance department contributes to the overall
            stability, growth, and success of the business.
            <br/>
             Its responsibilities extend beyond mere monetary
              transactions, encompassing budgetary oversight,
               investment decisions, and financial reporting to
                stakeholders. With its unwavering focus on efficiency,
                 transparency, and prudent financial stewardship,
                  the finance department serves as a linchpin for
                   sustainable business operations and long-term
                    viability.

          </p>
          </motion.div>
        </AnimatePresence>
        </div>

        
        



<div className="right-staff-about-admin">
  <img src={Finance} alt="Doctor" className="staff-image-about-finance" />
  
    </div>
       
    


      </div>
      







      <div ref={rightRef} className="admin-department-units">
        <div className="left-side">
          <div className="department-scope">
            <h2 className='units-first-title'>Finance Core</h2>
            <h2 className='units-second-title'>
              The Finance department <br />
              of the Hospital <br /> has the following sections
            </h2>

            <br />

            <Link to={'/ContactUs'} className='admin-btn'>Contact us</Link>
          </div>
        </div>
        <div className="right-side">
          {['General Account', 'Final Account', 'Cash Office', 'Expenditure Control', 'Salary', 'Revenue'].map((section, index) => (
            <motion.div
              className="text-block"
              key={index}
              initial={{ y: '100vh', opacity: 0 }}
              animate={{ y: rightInView ? 0 : '100vh', opacity: rightInView ? 1 : 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <h3>{`0 ${index + 1}`}</h3>
              <h4>{section}</h4>
              <p>{[
                'Our HR fosters a compassionate, dynamic workforce for exceptional patient care',
                'We optimize resource allocation and operations to enhance patient care outcomes',
                'We ensure continuous operation of essential facilities and equipment, prioritizing patient safety and comfort',
                'We ensure safe, efficient transportation for seamless care access',
                'We ensure essential supplies for smooth hospital operations and high-quality patient care',
                'The customer support team assists clients with their queries'
              ][index]}</p>
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        <HeadFinance />
      </AnimatePresence>

      <Footer />
    </>
  );
}

export default Finances;