import React from 'react';
import './Services.css';
import { RiPsychotherapyFill } from "react-icons/ri";
import { MdHealthAndSafety } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiCommunityLine } from "react-icons/ri";
import { GiBrain } from "react-icons/gi";
import { FaStethoscope } from "react-icons/fa";
import { FaHeartBroken } from "react-icons/fa";
import { FaAmbulance } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import UploadPayslip from '../../Pages/UploadPayslip'
import BlogTextEditor from '../../Pages/BlogTextEditor';

const Services = () => {
  
  const navigate = useNavigate();

  // Function to navigate to a specific section with smooth scrolling
  const navigateToSection = (sectionId) => {
    navigate(`/HospitalServices#${sectionId}`);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className='ServiceTop'>
        <div className='ServicesHeaders'>
        <span className="HeaderService"> Our Services</span>
     <h3 className="OurServices"> Explore the Services We Provide</h3>
    
        </div>
   
    <div className="Services-container">
    
      <div className="Services-section">

        <div className="ServiceCards" onClick={() => navigateToSection('general-psychiatric')} >
          <div className="ServicesContent">
         
            <RiPsychotherapyFill className="icons" />
          <p>General Psychiatric</p>
          </div>
        </div>

        <div className="ServiceCards">
          <div className="ServicesContent">
            <FaAmbulance className="Emergencyicons" />
            <p>Emergency Services</p>
          </div>
        </div>

        <div className="ServiceCards">
          <div className="ServicesContent">
            <FaHeartBroken className="icons" />
            <p> Social Support Service </p>
          </div>
        </div>

        <div className="ServiceCards">
          <div className="ServicesContent">
            <MdHealthAndSafety className="Babicons" />
           <p>Laboratory Services</p>
          </div>
        </div>

        <div className="ServiceCards">
          <div className="ServicesContent">
            <GiBrain className="ouricons" />
           <p>Forensic Psychiatry</p>
          </div>
        </div>

        <div className="ServiceCards">
          <div className="ServicesContent">
            <RiCommunityLine className="icons" />
           <p>Occupational Therapy </p>
          </div>
        </div>
        <div className="ServiceCards">
          <div className="ServicesContent">
            <FaStethoscope className="Genicons" />
           <p>Geriatics Psychiatry</p>
          </div>
        </div>

        <div className="ServiceCards">
          <div className="ServicesContent">
            <HiDotsHorizontal className="icons" />
           <p>Other Services</p>
          </div>
        </div>

      </div>
    </div>
   
    </div>

  

    
  );
};

export default Services;