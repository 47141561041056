import React, { useState } from 'react';
import './newsletter.css';
import { newsLetterService } from '../APIs/services/newsLetter';// Adjust the path accordingly
import { handleApiSuccess } from '../APIs/helpers/success-message';
import { handleApiErrors } from '../APIs/helpers/handleApi-errors';
import LOGO from '../Assets/neuroLogo-remove.png'

function NewsLetter() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the addSubscriber function from newsLetterService
      await newsLetterService.addSubscriber({ email });

      // Display success message
      handleApiSuccess('Thanks for subscribing ');

      // Clear the form fields after successful submission
      setEmail('');
    } catch (err) {
      // Handle errors
      handleApiErrors(err);
    }
  };

  return (
   <div className='new-letter-pages-first'>
    <div>

<img src={LOGO} alt='LOGO' className='logo-news-letter-page'/>
    </div>
    <div className='news-letter-header'>
      <div className='news-letter-headings'>
        <h3 className='header-news-title'>Subscribe to our Newsletter</h3>
        Subscribe to our newsletter and stay updated
      </div>
      <form onSubmit={handleSubmit} className='newsLetter'>
        <div className="newsLetter-input-container">
          <input
            type='email'
            placeholder='exampleemail@gmail.com'
            className='newsLetter-input'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type='submit' className='newsLetter-btn'>Subscribe</button>
        </div>
      </form>
    </div>
   </div>
  );
}

export default NewsLetter;