import React from 'react';
import './Hero.css';
import { FiEye, FiStar } from 'react-icons/fi'; // Import additional icons
import { FaUnity } from "react-icons/fa";
import { LuGoal } from "react-icons/lu";

const HeroCard = ({ front, back, icon }) => { // Add 'icon' prop
  const [isFlipped, setIsFlipped] = React.useState(false);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const renderIcon = () => {
    switch (icon) {
      case 'eye':
        return <FiEye size={24} style={{ color: '#01a85a', marginBottom: '10px' }} />;
      case 'FaUnity':
        return <FaUnity size={24} style={{ color: '#9a8e0c', marginBottom: '10px' }} />;
      case 'LuGoal':
        return <LuGoal size={24} style={{ color: '#01a85a', marginBottom: '10px' }} />;
      default:
        return null;
    }
  };

  return (
    <div className={`hero-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardFlip}>
      <div className="Hero-card-content front">
        <div className={`circle ${icon}`}>
          {renderIcon()} {/* Render the appropriate icon */}
        </div>
        <h3>{front}</h3>
      </div>
      <div className="Hero-card-content back">
        <div className={`circle back ${icon}`}></div>
        <p>{back}</p>
      </div>
    </div>
  );
};

const Cards = () => {
  return (
    <div className='HomeCards'>
      <h1 style={{ textAlign: 'center', marginTop: '4%', position: 'relative' }}>
        Navigating with our purpose in mind
        <div className="underline"></div>
        <br/>
        
      </h1>
      <div className='vison-core-mission'>
      <span>At Federal NeuroPsychiatric Hospital Dawanau Kano, we are driven by the following vision, core value and mission respectively. </span>
      </div>
     
      <div className="Hero-grid-container">
        <HeroCard
          front="Our Vision"
          back="To be a centre of excellence for evidence-based quality mental healthcare, education research and training."
          icon="eye" // Specify the icon for this card
        />
        <HeroCard
          front="Core Value"
          back="
          Team work, professionalism, Trust, Empathy, Humility, Fairness,and Respect"
          icon="FaUnity" // Specify the icon for this card
        />
        <HeroCard
          front="Our Mission"
          back="To provide evidence-based quality mental health care, training and research"
          icon="LuGoal" // Specify the icon for this card
        />
      </div>
    </div>
  );
};

export default Cards;