import React,{useState,useEffect} from 'react';
import './Footer.css'; // Import your CSS file for styling
import {  BsFacebook, BsInstagram, BsTwitter, } from 'react-icons/bs';
import { FaLinkedinIn,FaYoutube } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import Logo from '../../Assets/fed.png'
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";


const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(false);


 useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setShowButton(scrollTop > 0); // Show button when user scrolls up (scrollTop > 0)
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: animated scrolling
    });
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-brand">
          <a href="https://flowbite.com">
            <img
              className="footer-logo"
              src={Logo}
              alt="Flowbite Logo"
            />
          </a>
        </div>
        <div className="footer-links">

        <div className="footer-section">
            <h3 className="footer-section-title">FNPH Kano</h3>
            <ul className="footer-link-list">
              <li><a> we are proud to announce<br/>
               our transition to a Federal <br/>Institution,
                solidifying our <br/>commitment 
                to providing<br/> exceptional mental health <br/>
                care services to the region</a></li>
            
            </ul>
       
            <h3 style={{marginTop:'10px'}}>STAY CONNECTED</h3>
            <div className="footer-social-icons">
              
          <a href="https://www.facebook.com/p/Federal-Neuro-Psychiatric-Hospital-Dawanau-Kano-State-100095334090451/?_rdc=2&_rdr"><BsFacebook /></a>
          <a href="#"><BsInstagram /></a>
          <a href="https://x.com/FnphkanoNigeria?t=LF0Pnm65N_Cn9Trm8KrA_A&s=09"><BsTwitter /></a>
          <a href="https://www.linkedin.com/company/federal-neuropsychiatric-hospital-kano/posts/?feedView=all&viewAsMember=true"><FaLinkedinIn /></a>
          <a href="#"><FaYoutube /></a>
        </div>


          </div>
          {/** */}

          <div>
            
          </div>
          <div className="footer-section">
            <h3 className="footer-section-title">Home</h3>
            <ul className="footer-link-list">
              <li><IoIosArrowForward className='arrow-footers' /><a href="about-us">Hospital</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="#">Department</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="#">Services</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="#">Media Center</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="StaffManagement">Staff & MGT</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="/ContactUs">Contact us</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="/ContactUs">Faq</a></li>
            </ul>
          </div>
         
          <div className="footer-section">
            <h3 className="footer-section-title">Quick Links</h3>
            <ul className="footer-link-list">
              <li><IoIosArrowForward className='arrow-footers' /><a href="">Federal Government<br/> of Nigeria</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="https://www.health.gov.ng/">Ministry of Health</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="https://webmail.fnhkano.gov.ng/">Staff Email</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="#">E-Services</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="#">School Portal</a></li>
              <li><IoIosArrowForward className='arrow-footers' /><a href="#">Careers</a></li>
            </ul>
          </div>

          <div className="footer-section">
            <h3 className="footer-section-title">Contact Info</h3>
            <ul className="footer-link-list">
              <li><a href="#">FNPH, Kano, Nigeria .</a></li>
              <li><a href="#">Dawanau,Katsina road, Nigeria </a></li>
              <li><a href="#">info@fnhkano.gov.ng </a></li>
              <li><a href="#">+2348105226705 </a></li>
              <div><h3>Join our news letter today</h3></div>
              <div className='newsletter'>
      <div className="newsletter-input-container">
        <input
          type='email'
          placeholder='Enter your email'
          className='newsletter-input'
        />
        <button className='newsletter-btn'><FiSearch /></button>
      </div>
    </div>
             
            </ul>
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
     
      <div className="footer-bottom">
        <span className="footer-copyright">Copyright FNHD {currentYear} - All rights reserved . Developed by AmepTex Limited(Agebe Philip)</span>
       
      </div>

      <div className={`footer-button-scroll ${showButton ? 'show' : ''}`}>
        <button className="footer-button-two" onClick={scrollToTop}>
          Top<RiArrowDropDownLine className="footer-two-icon" />
        </button>
      </div>
    
      
    </footer>
  );
}

export default Footer;