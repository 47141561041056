import React, { useState, useEffect } from 'react'
import './BlogDetails.css'
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom'; // Import useParams to access URL parameters
import { blogService } from '../APIs/services/blog';
import BlogDetailsMight from './BlogDetailsMight';
import BlogDetailsNewsLetter from './BlogDetailsNewsLetter';
import BlogDetailsForm from './BlogDetailsForm';
import BlogDetailsOther from './BlogDetailsOther';
const BlogDetails = () => {
  const Params = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    const fetchBlogDetails = async () => {
      try {
        const data = await blogService.getBlogById(Params.id); // Fetch blog details by ID
        setBlogDetails(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };
    console.log('checking code', Params)
    fetchBlogDetails();
  }, [Params]);

  const [selectedMonths, setSelectedMonths] = useState({
    2022: null,
    2023: null,
    2024: null
  });

  const handleMonthChange = (year, month) => {
    setSelectedMonths(prevSelectedMonths => ({
      ...prevSelectedMonths,
      [year]: month
    }));
  };

  const generateMonthOptions = () => {
    const months = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];
    return months.map((month, index) => (
      <option key={index} value={index + 1}>{month}</option>
    ));
  };


  return (
    <>
      {loading ? (
        <div className="loading-container">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className='Header-details-blog'>
          <div className='Details-blog-subtitle'>
            <img src={blogDetails.image} alt='Details' className='Details-image-blog' />
            <h1 className='this-is-the-title'>{blogDetails.title}</h1>
            <div className='date-category'>
              <p className='category-title-details'>{blogDetails.date}</p>
              <h2 className='healthCare-details'>HealthCare</h2>
            </div>
            <h2 className='Details-blogs-des'>{blogDetails.description}</h2>



            <BlogDetailsForm />
          </div>

          <div className='Blog-details-Related-post'>
            <h1 className='top-title-details'>Other Posts</h1>
            <BlogDetailsOther />

            <div>
              <h1 className='blog-details-archives'>Archives</h1>
              <div className='selected-container-blog-details'>
                <div>
                  <select className='select-details-blog' value={selectedMonths[2022]} onChange={(e) => handleMonthChange(2022, e.target.value)}>
                    <option className='option-blogs-details' value="">2022</option>
                    {generateMonthOptions()}
                  </select>
                </div>
                <div>
                  <select className='select-details-blog' value={selectedMonths[2023]} onChange={(e) => handleMonthChange(2023, e.target.value)}>
                    <option className='option-blogs-details' value="">2023</option>
                    {generateMonthOptions()}
                  </select>
                </div>
                <div>
                  <select className='select-details-blog' value={selectedMonths[2024]} onChange={(e) => handleMonthChange(2024, e.target.value)}>
                    <option className='option-blogs-details' value="">2024</option>
                    {generateMonthOptions()}
                  </select>
                </div>
              </div>
            </div>

            <BlogDetailsNewsLetter />
          </div>



        </div>

      )}

      <BlogDetailsMight />
    </>
  )
}

export default BlogDetails












