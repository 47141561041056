import React from 'react';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Footer from '../component/Footer/Footer'
import HomePage from '../Assets/admin-officer.gif'
import { Link } from 'react-router-dom';

import './Admin.css';
import AdminDirector from './AdminDirector';
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import Faq from './Faq';

function Admin() {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });
  const [rightRef, rightInView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the right-side div is visible
  });
  return (
    <>
      <Header />
      <Navbar />

      <div className="about-component" >
        <div className="admin-content">
          <h1 >Administration Department</h1>
        </div>
      </div>



      <div ref={ref} className="consultation-container">
        <div className="left-staff">
       
          <div className="staff-sub-title">Dept Overview</div>
          <AnimatePresence>
          <motion.div

initial={{ x: "-100vh", opacity: 0 }}
animate={{ x: "0", opacity: inView ? 1 : 0 }}
transition={{ duration: 1.5, delay: 0.1 }}
exit={{ x: "-100vh", opacity: 0 }}
          
          >
          <p className='ConsultationParams'>
            The Administrative Department at the Federal Neuropsychiatric Hospital Dawanau
            Kano serves as the bedrock upon which the institution's operations thrive.
            Despite often operating behind the scenes, its contributions are
            indispensable to the hospital's functionality.
            In the context of the hospital, the Administration Department is
            responsible for a myriad of essential tasks, ranging from
            logistical coordination to resource management.
            These responsibilities are pivotal in ensuring
            that the hospital can deliver quality care to its
            patients while maintaining operational efficiency.
            Without the diligent efforts of the Administration
            Department, the smooth operation of the hospital
            would be at risk. Their dedication ensures that
            the hospital can focus on its core mission of
            providing comprehensive neuropsychiatric
            care to those in need.
          </p>
          <p className='ConsultationParams'>
            At our hospital, we believe in a holistic approach
            to mental health, addressing not only the symptoms
            but also the underlying factors contributing to
            each individual's condition. <br />

            Through a combination of therapy,
            medication, and supportive interventions,
            we empower our patients to reclaim their
            lives and pursue their fullest potential.

          </p>
          </motion.div>
        </AnimatePresence>
        </div>
        


        <div className="right-staff-about-admin">
       

<img src={HomePage} alt="Doctor" className="staff-image-about-admin" />

        </div>
       
      </div>


      <div ref={rightRef} className="admin-department-units">
        <div className="left-side">
          <div className="department-scope">
            <h2 className='units-first-title'>Administrative Nexus</h2>
            <h2 className='units-second-title'>
              The administrative department<br />
              comprises various<br /> sections and units
            </h2>
            <Link to={'/ContactUs'} className='admin-btn'>Contact us</Link>
          </div>
        </div>
        <div className="right-side">
          {['Human Resource', 'Planning', 'Maintenance', 'Transport', 'Store', 'Security'].map((section, index) => (
            <motion.div
              className="text-block"
              key={index}
              initial={{ y: '100vh', opacity: 0 }}
              animate={{ y: rightInView ? 0 : '100vh', opacity: rightInView ? 1 : 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <h3>{`0 ${index + 1}`}</h3>
              <h4>{section}</h4>
              <p>{[
                'Our HR fosters a compassionate, dynamic workforce for exceptional patient care',
                'We optimize resource allocation and operations to enhance patient care outcomes',
                'We ensure continuous operation of essential facilities and equipment, prioritizing patient safety and comfort',
                'We ensure safe, efficient transportation for seamless care access',
                'We ensure essential supplies for smooth hospital operations and high-quality patient care',
                'The customer support team assists clients with their queries'
              ][index]}</p>
            </motion.div>
          ))}
        </div>
      </div>


      
      <AdminDirector/>
      
   
   <Faq/>

      
      <Footer />
    </>
  );
}

export default Admin;