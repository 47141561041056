import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import './Faq.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleFAQ}>
        <span>{question}</span>
        <span>{isOpen ? <FaMinus /> : <FaPlus />}</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const Faq = () => {
  const [scrollDirection, setScrollDirection] = useState('down');
  const controls = useAnimation();

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
    };

    window.addEventListener('scroll', updateScrollDirection);

    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, []);

  useEffect(() => {
    controls.start({
      y: scrollDirection === 'down' ? 0 : -100,
      opacity: 1,
      transition: { duration: 0.5 },
    });
  }, [scrollDirection, controls]);

  const faqData = [
    {
      question: "What types of mental health treatments and services are available at the Federal Neuropsychiatric Hospital Kano?",
      answer: "The Federal Neuropsychiatric Hospital Kano offers a comprehensive range of mental health treatments and services designed to cater to various psychiatric conditions and psychological needs."
    },
    {
      question: "How long do typical treatment programs last at the Federal Neuropsychiatric Hospital in Kano?",
      answer: "The duration of treatment programs at the Federal Neuropsychiatric Hospital in Kano varies depending on the type and severity of the condition being treated."
    },
    {
      question: "What is the average waiting time for an appointment at the Federal Neuropsychiatric Hospital in Kano?",
      answer: "The waiting time for an appointment can vary based on the demand and availability of specialists. On average, it can take a few days to a few weeks."
    },
    {
      question: "How can patients access 24/7 emergency psychiatric services?",
      answer: "Patients in need of emergency psychiatric services can visit the hospital directly at any time. There is an emergency department that operates 24/7, where patients can receive immediate care and stabilization."
    },
    {
      question: "What kind of 24/7 support services are available?",
      answer: "The hospital offers a range of 24/7 support services, including emergency psychiatric care, crisis intervention, inpatient care, and continuous monitoring for patients with severe conditions. There are also on-call psychiatrists, nurses, and other healthcare professionals available at all times."
    },
    {
      question: "Does the hospital assist patients with financial difficulties?",
      answer: "The hospital's social support department can assist patients with financial difficulties by connecting them to government assistance programs, charitable organizations, and other resources that can help cover the cost of treatment and support services."
    },
    {
      question: "What are the hospital's operating hours?",
      answer: "The hospital operates 24/7 for inpatient and emergency services. Outpatient services typically have specific hours, usually from 8:00 AM to 4:00 PM Monday to Fridays."
    },
    {
      question: "What are the admission procedures for inpatient care?",
      answer: "Admission procedures typically include an initial assessment by a psychiatrist, completion of admission forms, and possibly a referral letter."
    }
    // Add more FAQs here
  ];

  return (
    <motion.div
      className="faq-container"
      animate={controls}
      initial={{ y: -100, opacity: 0 }}
    >
      <div className='frequently-asked-questions'>
        <h1>Frequently Asked Questions</h1>
        <p>Treatment Enquiry</p>
        <p>Duration Enquiries</p>
        <p>Appointment & Treatment</p>
        <p>General Hospital Enquiries</p>
      </div>
      <div>
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </motion.div>
  );
};

export default Faq;
