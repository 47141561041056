import React, { useState } from 'react';
import { TextField, Button, Container, Grid } from '@mui/material';
import EmergencyCall from './EmergencyCall';
import './emergency.css'
import useEnquiryStore from '../../APIs/stores/useEnquiryForm';
import ToastContainer from '../ui/ToastContainer';
import { handleApiSuccess } from '../../APIs/helpers/success-message';


const EmergencyForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const[message, setMessage] =useState('');
 
  const {addForm} =useEnquiryStore()

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
         
          await addForm({
            firstName,      
            lastName,       
            email,        
            phoneNumber ,
            message
          });

          // Display success message
          handleApiSuccess('Enquiry submitted successful');

          // Clear the form fields after successful submission
          setFirstName('');
          setLastName('');
          setEmail('');
          setPhoneNumber('');
          setMessage('');
      } catch (err) {
          // Handle errors
          console.log({err:'sorry check your data again'})
      }
  };

 

  const variantColor = "#00A859"; // Desired variant color

  return (
    <>
    <div className='emegency-form-header-fed' style={{ background: `linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
    linear-gradient(0deg, #FFFFFF, #FFFFFF)`}}>

      <ToastContainer/>

      <div className='make-enquiry'>

<div className='Form-with-instant-response'>
  <h5 className='with-access-to'>With Access to</h5>
  <h2 className='hours'>24 HOURS</h2>
  <h2 className='instant-emergency'>INSTANT EMERGENCY</h2>
  <h2>Response</h2>
</div>

    
      <Container className='emergency-form' maxWidth="sm"
       style={{marginLeft:'60px',marginBottom:'60px', animation: 'dance 3s infinite alternate',
       marginTop:'60px',borderRadius:'20px', 
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75),'+
              'linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),'+
              'linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),'+
              'linear-gradient(0deg, #FFFFFF, #FFFFFF)'}} >
        <div className='form-title-content'>
      <div className="Consultaion">Need Help ? </div>
        <div className="sub-title">Make an Enquiry</div>
        </div>
      
        <form onSubmit={handleSubmit} className='emergency-form-content'  >
        
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                variant="standard"
                fullWidth
              
                margin="normal"
                value={firstName}
                onChange={(e)=>setFirstName(e.target.value)}
              
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="standard"
                fullWidth
              
                margin="normal"
                value={lastName}
                onChange={(e)=>setLastName(e.target.value)}
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="standard"
                fullWidth
              
                margin="normal"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                variant="standard"
                fullWidth
                
                margin="normal"
                value={phoneNumber}
                onChange={(e)=>setPhoneNumber(e.target.value)}
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="Message"
                variant="standard"
                multiline
                rows={4}
                fullWidth
               
                margin="normal"
                value={message}
                onChange={(e)=>setMessage(e.target.value)}
                sx={{ '& .MuiOutlinedInput-root': 
                { '& fieldset': 
                { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained"
           style={{ backgroundColor: '#00A859'
           ,gap:'8px',lineHeight:'24px',fontWeight:500, 
           height:'46px', color: '#FFFFFF', marginTop: '30px', 
           padding:'8px, 24px, 8px, 24px',borderRadius:'4px' }}>
           Submit Enquiry
          </Button>                   
  
        </form>
      </Container>
    </div>
    </div>

<EmergencyCall/>
    
    </>
  );
};

export default EmergencyForm;