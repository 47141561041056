import React from 'react'
import { TextField, Button, Grid } from '@mui/material';

const BlogDetailsForm = () => {
    const variantColor = "#00A859"; 
  return (

    <div>
      <form  className='emergency-form-content'>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  id="Name"
                  name="Name"
                  label="Name"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  margin="normal"
                 
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="message"
                  name="message"
                  label="comment"
                  variant="standard"
                  multiline
                  rows={4}
                  fullWidth
                  margin="normal"
                 
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: '#00A859',
                gap: '8px',
                lineHeight: '24px',
                fontWeight: 500,
                height: '46px',
                color: '#FFFFFF',
                marginTop: '30px',
                padding: '8px 24px',
                borderRadius: '4px'
              }}
            >
              Submit Comment
            </Button>
          </form>
    </div>
  )
}

export default BlogDetailsForm
