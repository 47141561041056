import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Abouts from '../Assets/Historical.jpg'

import Footer from '../component/Footer/Footer'
import Hero from '../component/Hero/Hero'
import Organogram from '../Assets/neuro-Organogram.jpg'
import About from '../component/About-us/About'
import Admins from '../Assets/admin-block.jpg'
import './About.css';
import Statistics from './Statistics';

function AboutUs() {
  const [isVisible, setIsVisible] = useState(false);

  // Animation for sliding in when the component is visible
  const slideIn = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });

  // Function to check if the component is visible on the screen
  const checkVisibility = () => {
    const element = document.querySelector('.consultation-container');
    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      setIsVisible(elementTop < window.innerHeight);
    }
  };

  // Add event listener to check visibility on component mount
  React.useEffect(() => {
    checkVisibility();
    window.addEventListener('scroll', checkVisibility);
    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  return (
    <>
      <Header />
      <Navbar />

      <div className="about-history-component" >
        <div className="content-history">
          <h1 >About Us</h1>
          <p>Healing minds, Restoring Lives:<br/> To provide evidence-based, quality, person-centred, mental healthcare, robust training and reliable research.</p>
        </div>
      </div>
      <animated.div style={slideIn} className="consultation-container">
        <div className="left-staff-history">
          <div className="staff-sub-title">
            Our Brief History
          </div>
          <p className='ConsultationParams'>
            The Federal Neuropsychiatric Hospital Dawanua, Kano (FNPHD) was
            a state Psychiatric Hospital established since 1936 as a Lunacy
            Asylum by the Kano Native Authority at Gwaron Dutse Kano.
            It was moved to its present site in 1979-80 and ultimately
            became Kano Psychiatric Hospital Dawanau.
            The Kano state Government requested for the hospital
            to be converted to a Federal Neuropsychiatric Hospital Dawanau.
            The Federal Government approved it's conversion in 2021 and a
            Memorandum of Understanding (MOU) and eventual takeover was
            concluded in may 2023.
          </p>
          <p className='ConsultationParams'>
            The Federal Neuropsychiatric Hospital Dawanau Kano is currently
            an 160-bed Hospital located at Dawanau, Dawakin Tofa Local
            Government Area Kano State. It commences operation
            in June 2023 with the aim of Realizing the mandate of Clinical Services,
            fostering training and research. It has seven wards with average bed
            capacity of 24 bed each. Additionally, it houses administrative offices,
            a pharmacy, a laboratory, and a store (storage facility). <br />
            Moreover, it boasts an Outpatient unit equipped with six consulting rooms and a
            dedicated call duty room.
            With its comprehensive infrastructure and commitment to excellence,
            the Federal Neuropsychiatric Hospital Dawanau is poised to make significant
            strides in mental healthcare delivery, education, and scientific advancement
            within the region and beyond.
          </p>
        </div>
        <div className="right-staff-about">
          <animated.img style={slideIn} src={Abouts} alt="Doctor" className="staff-image-about" />
          <animated.img style={slideIn} src={Admins} alt='second' className='doctor-image-about second-image'/>
        </div>
      </animated.div>

      <Hero />
      <Statistics />
      <About />
      <div className='organogram'>
        <div className="organogram-image">
          <img src={Organogram} alt='dr agebe' />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AboutUs;
