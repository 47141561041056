
import makeRequest from '../helpers/Request';

const blogService = {
  addBlog: async (newBlog) => {
    try {
      const url = '/api/blog/blogPost';
      const { data } = await makeRequest.post(url, newBlog);
      return data;
    } catch (err) {
      throw err;
    }
  },

  updateBlog: async (blogId, updatedBlog) => {
    try {
      const url = `/api/blog/blogPosts/${blogId}`;
      await makeRequest.put(url, updatedBlog);
    } catch (err) {
      throw err;
    }
  },

  getBlogs: async () => {
    try {
      const url = '/api/blog/blogPosts';
      const { data } = await makeRequest.get(url);
      return data;
    } catch (err) {
      throw err;
    }
  },
  
  getBlogById: async (blogId) => {
    try {
      const url = `/api/blog/blogPosts/${blogId}`;
      const { data } = await makeRequest.get(url);
      return data;
    } catch (err) {
      throw err;
    }
  },

  deleteBlogById: async (blogId) => {
    try {
      const url = `/api/blog/blogPosts/${blogId}`;
      await makeRequest.delete(url);
    } catch (err) {
      throw err;
    }
  },
  //TODO implement creating comments on instance
  addComment: async (newComment)=>{
    try{
      const url ='/api/blog/blogPost/comment';
      const {data} = await makeRequest.post(url,newComment);
      return data;

    }
    catch (err){
      throw err;
    }
  },
//TODO implement fetch comments by ID parameter
  getCommentById: async (commentId)=>{
    try{
      const url =`/api/blog/blogPost/comment/${commentId}`;
      const {data} = await makeRequest.get(url);
      return data;

    } catch (err) {
      throw err
    }
  }
  

};

export { blogService };











