
import makeRequest from '../helpers/Request';

const newsLetterService = {
  addSubscriber: async (newSubscriber) => {
    try {
      const url = '/api/newsletter/subscribe';
      const { data } = await makeRequest.post(url, newSubscriber);
      return data;
    } catch (err) {
      throw err;
    }
  },

  updateSubscriber: async (email, updatedSubscriber) => {
    try {
      const url = `/api/newsletter/update/${email}`;
      await makeRequest.put(url, updatedSubscriber);
    } catch (err) {
      throw err;
    }
  },

  getSubscribers: async () => {
    try {
      const url = '/api/newsletter/subscribers';
      const { data } = await makeRequest.get(url);
      return data;
    } catch (err) {
      throw err;
    }
  },
  

  deleteUnsubscribeById: async (email) => {
    try {
      const url = `/api/newsletter/unsubscribe/${email}`;
      await makeRequest.delete(url);
    } catch (err) {
      throw err;
    }
  },
};

export { newsLetterService };











