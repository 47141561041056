import React, { useState ,useEffect} from 'react';
import { Navbar } from 'flowbite-react';
import './NavBar.css'; // Import the CSS file
import Image from '../../Assets/Logo.png';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { FaAngleDown, FaBars, FaTimes } from 'react-icons/fa'; 
import { Link, Outlet } from 'react-router-dom';


const Navigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [fix, setFix] =useState(false);
  const[sticky, setSticky] =useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  function setFixed (){
    if(window.scrollY >=100)
    setFix(true)
  else{
    setFix(false)
  }
  }

  window.addEventListener("scroll",setFixed)


  useEffect (()=>{
    const handleScroll = ()=>{
      setSticky(window.scrollY > 200)
    };
    window.addEventListener('scroll', handleScroll);
    return ()=> window.removeEventListener('scroll',handleScroll)
  })
  return (
    <div>
      <Navbar fluid rounded className="navbar">
        <Container style={{display:'flex', margin:'10px'}}>
          <Navbar.Brand className='NavbarHome'>
            <Link to="/">
              <img src={Image} alt='Image' className='Image' />
            </Link>
          </Navbar.Brand>

           {/* Toggle button for mobile menu */}
           <div className="mobile-menu-toggle" onClick={handleMobileMenuToggle}>
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </div>

          <Navbar.Collapse className={`navbar-collapse ${mobileMenuOpen ? 'open' : ''}`}>
            <ul className="navbar-links">
              
              <NavDropdown class="active" title={<span className="about-us">Hospital <FaAngleDown className="dropdown-icon" /></span>} id="basic-nav-dropdown">
                <NavDropdown.Item href="AboutUs">About Us</NavDropdown.Item>
                <NavDropdown.Item href="/Career">E-Services</NavDropdown.Item>
                <NavDropdown.Item href="#">Board of Directors</NavDropdown.Item>
              </NavDropdown>
              <li>
                <Link to="/HospitalServices">Services</Link>
              </li>
              <NavDropdown title={<span className="about-us">Departments <FaAngleDown className="dropdown-icon" /></span>} id="basic-nav-dropdown">
                <NavDropdown.Item href="/Admin">Administration</NavDropdown.Item>
                <NavDropdown.Item href="/Finance">Finance and Accounts</NavDropdown.Item>
                <NavDropdown.Item href="/Clinical">Clinical Services</NavDropdown.Item>
                <NavDropdown.Item href="Directorate">Directorate</NavDropdown.Item>
              </NavDropdown>
              <li>
                <Link to="/StaffManagement">Staff & MGT</Link>
              </li>
              <li>
                <Link to="/ContactUs">Contact us</Link>
              </li>
              <li >
                <Link to="/">Education</Link>
              </li>
              <NavDropdown title={<span className="about-us">Media <FaAngleDown className="dropdown-icon" /></span>} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to='/News'>News</NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/publications'>Publications</NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/Career'>News Letter</NavDropdown.Item>
              </NavDropdown>
             
              <button className='accounts-btn'>
              <NavDropdown  title={<span className="about-us">Accounts <FaAngleDown className="dropdown-icon" /></span>} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to='/Login'>Login</NavDropdown.Item>
                <NavDropdown.Item as={Link} to='http://webmail.fnhkano.gov.ng/'>Mail</NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/Career'>Career</NavDropdown.Item>
                <NavDropdown.Item as={Link} to='https://www.dropbox.com/login'>E-Library</NavDropdown.Item>
                
              </NavDropdown>
              </button>
              
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  );
};
export default Navigation;
