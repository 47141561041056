
import React,{useEffect,useState} from 'react';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Footer from '../component/Footer/Footer'
import { Grid, TextField, Button } from '@mui/material';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { handleApiSuccess } from '../APIs/helpers/success-message';
import useConversationStore from '../APIs/stores/useConversation';
import ToastContainer from '../component/ui/ToastContainer';



import './ContactUs.css';
function ContactUs() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const {addForm} =useConversationStore();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
       
        await addForm({
          name,      
          email,       
          message        
         
        });
        // Display success message
        handleApiSuccess({message:'thanks'});

        // Clear the form fields after successful submission
        setName('');
        setEmail('');
        setMessage('');
    } catch (err) {
        // Handle errors
        console.log({err:'wrong parameter check again'})
    }
};
  useEffect(() => {
    // Initialize the map
    const map = L.map('map').setView([12.020461, 8.535891], 13); // Coordinates for Federal Neuropsychiatric Hospital Dawanau, Kano

    // Add the base map layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Add a marker for the hospital
    const marker = L.marker([12.020461, 8.535891]).addTo(map);
    marker.bindPopup('<b>Federal Neuropsychiatric Hospital Dawanau, Kano</b>').openPopup();
  }, []);

  return (
    <>
      <Header />
      <Navbar />

      <div className="Contact-Us" >
       
       <ToastContainer/>
        <div className="contact-us-content">
          <h1 >Contact Us</h1>
          <p> Get the info you're looking for right now.We would love to hear from you</p>
        </div>
      </div>
      <div className='contact-us-form-container'>
      {/* Left side - Form */}
      <div className='form-contacting' style={{ width: '80%',padding:'30px' }}>
        <div className='start-conversation'>
            <h2>Let's start a conversation</h2>
        </div>
        <form className='form-forms' onSubmit={handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <TextField
                id="name"
                name="name"
                label="Your Name"
                variant="standard"
                fullWidth
                margin="normal"
                value={name}
                onChange = {(e)=>setName(e.target.value)}
              />
            </Grid>
            
            <Grid item xs={6}>
            <TextField
        id="email"
        name="email"
        label="Email"
        variant="standard"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </Grid>
            
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="Message"
                variant="standard"
                multiline
                rows={4}
                fullWidth
                margin="normal"
                value={message}
                onChange={(e)=>setMessage(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" style={{ backgroundColor: '#00A859', color: '#FFFFFF', marginTop: '40px', padding: '8px, 16px, 8px, 16px', borderRadius: '4px' }}>
            Send Message
          </Button>
        </form>
      </div>
      
      {/* Vertical Line Container */}
  <div style={{ display: 'flex', alignItems: 'center', margin: '0 20px' }}>
    <div style={{ width: '0.1px', height: '100%', backgroundColor: '#ccc' }}></div>
  </div>
      {/* Right side - Contact Information */}
      <div  className='contact-social-icons'>
        <div>
          <h2 className='official-email' style={{fontFamily: 'Karla',fontSize: '24px',
          fontWeight: 700,lineHeight: '36px',letterSpacing: '0em',}}>
             Current Office Address</h2>
          <p>Federal Neuropsychiatric Hospital Dawanau</p>
          <p>P.M.B 11923, Katsina Road,</p>
          <p>Kano State, Nigeria</p>
        </div>
        <div>
          <h2 className='official-email' style={{fontFamily: 'Karla',fontSize: '24px',
          fontWeight: 700,lineHeight: '36px',letterSpacing: '0em',}}>
            Official Email Address</h2>
          <p>info@fnhkano.gov.ng</p>
          <p>official@fnhkano.gov.ng</p>
        </div>
        <div>
          <h2 className='official-email'>Phone Number</h2>
          <p>+234-703-423-3125</p>
          <p>+234-703-423-3125</p>
        </div>
        <div>
          <h2 className='official-email'>STAY CONNECTED</h2>
          <FaFacebook  style={{ fontSize: '24px', marginRight: '10px',color:'#1877F2' }} />
          <FaXTwitter style={{ fontSize: '24px', marginRight: '10px' }} />
          <FaInstagram style={{ fontSize: '24px', marginRight: '10px',color:'#1877F2' }} />
          <FaLinkedin style={{ fontSize: '24px', marginRight: '10px',color:'#1877F2' }} />

          <FaYoutube style={{ fontSize: '24px', marginRight: '10px',color:' #FF0000' }}/>
        </div>
      </div>
    </div>

    <div className='contact-us-map'>
       <h2 className='official-email'>Locate us on the map</h2>
        </div> 
      <div className='contact-mapping'>
       
      <div id="map" className="container-map-contact"></div>
      </div>
      
      
   

      
      <Footer />
    </>
  );
}

export default ContactUs;