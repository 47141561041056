import React, { useState, useEffect } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import './BlogNews.css';
import { blogService } from '../../APIs/services/blog';
import { Link } from 'react-router-dom';

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalDuration = 4000; // Interval duration in milliseconds
  const visibleCardsCount = 3; // Number of visible cards in the grid
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const data = await blogService.getBlogs();
        setBlogs(data);
        setLoading(false);
      } catch (error) {
        console.error('error fetching blogs:', error);
      }
    };

    fetchBlogs();
  

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % blogs.length);
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [blogs.length]); // Listen for changes in blogs length

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='new-blogs'>
      <div className='blog-title'>
        <h1>Media & Recent News</h1>
      </div>
      <div className='blog-card-container'>
        {Array.from({ length: visibleCardsCount }).map((_, index) => {
          const blogIndex = (currentIndex + index) % blogs.length;
          const blog = blogs[blogIndex];

          // Check if blog is defined
          if (!blog) {
            return null; // Skip rendering if blog is undefined
          }

          return (
            <div
              key={index}
              className={`blog-card ${
                index === 0 ? 'slide-in' : 'slide-out'
              }`}
            >
              <img
                src={blog.image}
                alt='Blog Image'
                className='blog-card-image'
              />
              <div className='blog-card-content'>
                <button className='world-epilepsy'>{blog.date}</button>
                <h2 className='blog-card-title'>{blog.title}</h2>
                <p className='blog-card-description'>{blog.description}</p>
              </div>
              <hr />
              <div className='blog-readmore-btn'>
                <Link to={`/BlogDetails/${blog._id}`} className='blog-card-button'>
                  Read More<FaLongArrowAltRight />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogCard;
