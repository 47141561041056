// src/components/LoginPage.js

import React,{useState} from 'react';
import { FaUser } from 'react-icons/fa'; // Import user icon from react-icons
import Logo from '../Assets/neuroLogo.jpg';
import authService from '../APIs/services/auths';

import './Login.css'; // Import CSS file for styling
import ToastContainer from '../component/ui/ToastContainer';
import { handleApiSuccess } from '../APIs/helpers/success-message';
import {useNavigate} from "react-router-dom";


import useLoading from '../APIs/helpers/useLoader';



const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { startLoading, stopLoading } = useLoading();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      startLoading()
      await authService.login({ email, password });
      handleApiSuccess({message:'Login Successful'})

       // Navigate to the welcome page upon successful login
       navigate("/BlogTextEditor");
       
       stopLoading()
    } catch (error) {
     
     
     
    }
  };

  return (
    <div className="login-page">
      <ToastContainer/>
      <div className="login-container">
        <img src={Logo} alt="Logo" className="logo" />
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="user-input"> {/* Wrap input field and icon in a container */}
            <input className='login-input' 
            type="email" placeholder="Email"
            value={email}
            onChange={(e)=> setEmail(e.target.value)}
            
            />
            <FaUser className="user-icon" />
          </div>
          <div className="input-container">
          <input className='login-input' 
          type="password" placeholder="Password"
          value={password}
          onChange={(e)=> setPassword(e.target.value)}
           />
         
          </div>
        <div>
          
          <button className='User-Button' type="submit">Login Now</button>
         
       
        </div>
         
        </form>
       
      </div>
    </div>
  );
};

export default Login;


