import React from 'react';
import './Career.css';


function Career() {
  return (
    <>
    <div className="Career">
      <h1 className='Career-heading'>Coming Soon</h1>
      <p className='career-sub-heading'>We are working hard to bring you something awesome. Stay tuned!</p>
    </div>

   
    </>
    
  );
}

export default Career;
