
import makeRequest from '../helpers/Request';

const enquiryService = {
  addForm: async (newForm) => {
    try {
      const url = '/api/form/submit';
      const { data } = await makeRequest.post(url, newForm);
      return data;
    } catch (err) {
      throw err;
    }
  },

  updateForm: async (formId, updatedForm) => {
    try {
      const url = `/api/form/submissions/${formId}`;
      await makeRequest.put(url, updatedForm);
    } catch (err) {
      throw err;
    }
  },

  getForms: async () => {
    try {
      const url = '/api/form/submissions';
      const { data } = await makeRequest.get(url);
      return data;
    } catch (err) {
      throw err;
    }
  },
  
  getFormById: async (Id) => {
    try {
      const url = `/api/form/submissions/${Id}`;
      const { data } = await makeRequest.get(url);
      return data;
    } catch (err) {
      throw err;
    }
  },

  deleteFormById: async (formId) => {
    try {
      const url = `/api/form/submissions/${formId}`;
      await makeRequest.delete(url);
    } catch (err) {
      throw err;
    }
  },
};

export { enquiryService };











