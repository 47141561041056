// authService.js

import makeRequest from '../helpers/Request';

import useAuthStore from '../stores/useAuths';

const authService = {
  login: async (credentials) => {
    try {
      const url = '/api/auth/login';
      const { data } = await makeRequest.post(url, credentials);
      useAuthStore.setState({ response: data });
    } catch (error) {
      throw error;
    }
  },
};

export default authService;
