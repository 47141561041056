import React from 'react';
import { TbAmbulance } from 'react-icons/tb';
import Director from '../Assets/director admin.png';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import './AminDirector.css'; // Import the CSS file

const AdminDirector = () => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="doctor-profile-container">
      <motion.div
        className="doctor-container"
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.5 }}
      >
        <div className="left-content-admin">
          <div className="doctor-image-wrapper">
            <motion.img 
              src={Director} 
              alt="Doctor" 
              className="doctor-image-admin-head"
              variants={animationVariants}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className="doctor-info-medical">
            <motion.h1 
              className='DoctorTitle'
              variants={animationVariants}
              transition={{ duration: 0.5 }}
            >
              Muhammad S. Sulaiman
            </motion.h1>
          </div>
        </div>
        <div className="right-content-admin">
          <div className="bullet-list-admin">
            <div className='Director'>
              <motion.h1 
                className="doctor-title"
                variants={animationVariants}
                transition={{ duration: 0.5 }}
              >
                Meet Head of Administration
              </motion.h1>
            </div>
            <motion.li
              variants={animationVariants}
              transition={{ duration: 0.5 }}
            >
              <div className="circle-about">
                <TbAmbulance className="icon" />
              </div>
              <span className="title">
                <span className="bold-text">Academic Journey</span><br />
                Muhammad S. Sulaiman is a highly accomplished professional with
                 a rich academic background and extensive experience in public 
                 administration. He holds a Masters in Public Policy and 
                 Administration from B.U.K, along with a Bachelor's degree
                  in Political Science. Additionally, Sulaiman has 
                  pursued advanced diplomas in areas such as Information 
                  Technology, English Education, Accounting and Audit, and Law.
              </span>
            </motion.li>
            <motion.li
              variants={animationVariants}
              transition={{ duration: 0.5 }}
            >
              <div className="circle-about">
                <TbAmbulance className="icon" />
              </div>
              <span className="title">
                <span className="bold-text">Brief Service Journey</span><br />
                Throughout his career, Sulaiman has held various leadership 
                positions in government agencies and ministries, showcasing 
                his expertise in administration, human resources, and 
                service improvement. Notably, he has served as Director 
                of Establishment in the Office of the Head of Service
                 since 2022, after previously holding roles such as 
                 Director of Human Resources and Service Improvement 
                 in the same office from 2016 to 2022.
                 Sulaiman's career also includes significant contributions
                  to the Kano state government, where he served as Director 
                  of Admin and General Services in the Ministry of Environment 
                  and the Kano State Bureau of Statistics. He has also held key 
                  roles within the Deputy Governor's Office, Ministry of Education, 
                  and Kano State Board of Internal Revenue.With his strong educational 
                  foundation and diverse professional experience, Muhammad S. Sulaiman 
                  continues to play a pivotal role in administrative excellence and 
                  service delivery.
              </span>
            </motion.li>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AdminDirector;
