import React from 'react';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Footer from '../component/Footer/Footer'
import './Directorate.css';
function Directorate() {
 

  return (
    <>
      <Header />
      <Navbar />

      <div className="about-component" >
        <div className="admin-content">
          <h1 >Directorate</h1>
        </div>
      </div>
      <div className="directorate-container">
        <div className="left-directorate">
          <h2> The Hospital has the following departments/units under the Medical Director:</h2>
          <p className='directorate-header'>
        <h4>  1. Training and Research Department </h4>

The department has Training  and  Ethics & Research Units. It boasts of well-established training culture of qualified staff that can effectively train health professionals with accredited training facilities and first hand training tools in an enabling environment. 

The Ethics Committee of the Ethics and Research Unit is responsible for reviewing research proposals and protocols. :

Research Grants:

Depression Genetics in Africa: Welcome Trust Fund, UK:  2022 – 2026 (Ongoing).
Bridging the Epilepsy Treatment Gap in Africa: National Institutes of Health (NIH): 2019 – 2024 (Ongoing).
Mental Health Services Scale up in Nigeria (mhSUN): cbm Australia: 2014 – 2018.
<br/>
 

<h4>
2. Internal Audit Unit 
</h4>

<h4>
3. Procurement Unit
</h4>
<h4>
4. National Health Insurance Authority (NHIA) Unit
</h4>
 
<h4>
5. Information and Protocol Unit:
</h4>


 



The unit is responsible for information dissemination
 handling, protocol and liaison with the press (general 
 reception and care of hospital quests) mass media. 
 It also handles relations and publications of the
  hospital News Bulletins.
 
<h4>
    
6. Legal Services Unit 
</h4>

 
<h4>
    
7. Anti-Corruption Unit
</h4>

 

<h4>
8. SERVICOM Unit
</h4>

 
<h4>
    
9. ICT Unit
</h4>

The objective function of the unit is to
 improve effectiveness, efficiency and 
 comprehensive mental health services that 
 are qualitative, acceptable, accessible and
  affordable, in a friendly interface, using 
  contemporary ICT solutions in order to improve
   health care delivery where retrieving and
    analyzing information will carry out and
     to bring patient closer to care givers,
      making access to the best health care 
      solution and available to the remotest
       parts of the world.
      

<h4>
UNIT ACTIVITIES INCLUDE:
</h4>


Manage the Hospital’s Website.
Provide ICT hardware/software maintenance.
Provide Technical support for the use of ICT.
Acts as an exit of approved hospital information for every ones consumption through the website and other e-methods.
Ensures the security of electronic systems and its gadgets in the Hospital
Teaching our Nurses in School of Post Basic Psychiatric

<h4>UNIT ACHIEVEMENT</h4>

The Unit recorded remarkable achievements include:

Constant updating the Hospital website that designed and hosted successfully where people/client are getting to know the services of the Hospital all over the world far and near
The Unit is always ready in providing system Evaluation, Installation and Maintenance.
The Unit also renowned of helping School of Post Basic Psychiatric Nursing where students are prepared with the knowledge and ICT skills needed in order to serve their community, state, nation and the world in general.

          </p>
         
        </div>



        
      </div>

      
 
      
   

      
      <Footer />
    </>
  );
}

export default Directorate;