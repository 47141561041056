import React,{useState,useEffect} from 'react'
import { blogService } from '../APIs/services/blog';
import {Link} from 'react-router-dom'
import './BlogDetails.css'

const BlogDetailsOther = () => {
    const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            const data = await blogService.getBlogs();
            setBlogs(data);
            setLoading(false);
          } catch (error) {
            console.error('error fetching blogs:', error);
          }
        };
    
        fetchBlogs();
      }, []);
  return (
    <div>
      <div className='blog-related-posts'>
            {/* Related posts */}
            
            {loading ? (
            <p>Loading...</p>
          ) : (
            blogs.map((blog) => (
              <Link to={`/BlogDetails/${blog._id}`}>
              <div className='related-post'>
              
              <img src={blog.image} className='other-post-images' alt='logo'/>
              <Link to={`/BlogDetails/${blog._id}`} className='other-post-title'>{blog.title}</Link>
            </div>
              </Link>
            
              ))
            )}
          </div>
    </div>
  )
}

export default BlogDetailsOther
