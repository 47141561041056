import React,{useState,useEffect} from'react';
import Header from '../component/Header/Header';
import Navbar from '../component/NavBar/Navbar';
import Hall from '../Assets/Hall.jpg'
import Finance from '../Assets/md.jpg'
import Footer from '../component/Footer/Footer'
import Prof from  '../Assets/Medical director.png'
import DirectorAdmin from '../Assets/director admin.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeadofPublic from '../Assets/junia.jpg'
import HeadofIct from '../Assets/headofict.jpg'
import HeadMaintenance from '../Assets/head_of_maint.png'
import DirectorLab from '../Assets/head og Lab-edit.jpg'
import HeadEnvironmental from '../Assets/head of environmental.jpg'
import Statistics from '../Assets/planning_research.png'
import Nutrition from '../Assets/Mary_David.png'
import Pharmacy from '../Assets/head of pharmacy-edit.jpg'
import HeadClinical from '../Assets/clinicals-director.jpg'


import './StaffManagement.css';


const members = [
  {
    name: 'Mr. Abdullahi A Kyaudai',
    position: 'Head of Finance & Accounts',
    image: Finance,
  },
  {
    name: 'Prof. Auwal S Salihu',
    position: 'Medical Director',
    image: Prof,
  },
  {
    name: 'Muhammad S. Sulaiman',
    position: 'Director Administration',
    image: DirectorAdmin,
  },
  {
    name: 'Mr. Aminu Shehu Ibrahim',
    position: 'Head of Clinical',
    image: HeadClinical,
  },
  {
    name: 'Mr. Abdullahi A. Kyaudai',
    position: 'Director Finance & Accounts',
    image: Finance,
  },
  {
    name: 'Muhammad S. Sulaiman',
    position: 'Director Administration',
    image: DirectorAdmin,
  },
 
];


const StaffManagement =()=> {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalMembers = 6; // Assuming you have a total of 6 team members
  const intervalDuration = 8000; // Interval duration in milliseconds (adjust as needed)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 3) % totalMembers);
    }, intervalDuration);

    return () => clearInterval(interval);
  }, []);
  return (
    <>

      <Header />
      <Navbar />

      <div className="component-management" >
        <div className="content-management">
          <h1 >Staff & Management</h1>
          <p>Meet our able management and the people who make our Institution feel like home.</p>
        </div>
      </div>

      <div className="consultation-container">
        <div className="left-staff-management">
          <div className="Consultaion">Management</div>

          <div className="staff-sub-title">Staff & Management</div>
          <p className='ConsultationParams'>
            With a dedicated team of skilled professionals, including psychiatrists,
            psychologists, nurses, and support staff, we provide comprehensive
            and compassionate care to our patients. Our commitment to
            excellence drives us to continuously innovate and improve
            our services, ensuring that every individual receives
            personalized treatment tailored to their unique needs.



          </p>
          <p className='ConsultationParams'>
            At our hospital, we believe in a holistic approach
            to mental health, addressing not only the symptoms
            but also the underlying factors contributing to
            each individual's condition. <br />

            Through a combination of therapy,
            medication, and supportive interventions,
            we empower our patients to reclaim their
            lives and pursue their fullest potential.

          </p>


        </div>
        <div className="right-staff-management">

          <img src={Hall} alt="Doctor" className="staff-image-management" />


        </div>
      </div>

{/*  top management section*/}
<div className='component'>
      <div className='To-management-kano'>
        <div className='Top-executive'>
          <h1>Top Executive Management</h1>
        </div>

        <div className='team-grid-management'>
          {Array.from({ length: 3 }).map((_, i) => {
            const index = currentIndex + i;
            const member = members[index % totalMembers]; // Ensure cyclic iteration

            return (
              <div className={`team-member-card ${i === 0 ? 'slide-in' : ''}`} key={index}>
                <img src={member.image} alt={member.name} className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>{member.name}</h2>
                  <p className='member-position'>{member.position}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>

    

    <div className='component'>
      <div className='To-management-kano'>
        <div className='Top-executive'>
          <h1>Other Staff & Units Head</h1>
        </div>

        <div className='team-grid-management'>
           
              <div className='team-member-card'>
                <img src={HeadofIct} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>Agebe Philip</h2>
                  <p className='member-position'>Head of ICT</p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={HeadofPublic} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>Oche Junia Ogbotu</h2>
                  <p className='member-position'>Head of Public Relations</p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={HeadMaintenance} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>QS JIBRIL MUHAMMAD BASHIR</h2>
                  <p className='member-position'>Head of Maintenance</p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={DirectorLab} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'> Mustapha Ismail Adam </h2>
                  <p className='member-position'>Head of  Laboratory </p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={Statistics} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>Isa Abbas</h2>
                  <p className='member-position'>Head of planing research and statistics </p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={HeadEnvironmental} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>Abdullahi IBrahim</h2>
                  <p className='member-position'>Head of  Environmental</p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={Nutrition} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>Mary David Aladi</h2>
                  <p className='member-position'>Head of Nutrition</p>
                </div>
              </div>
              <div className='team-member-card'>
                <img src={Pharmacy} alt='' className='team-member-image' />
                <div className='member-details'>
                  <h2 className='member-name'>Husaini Muhammad Nuhu</h2>
                  <p className='member-position'>Head of Pharmacy</p>
                </div>
              </div>
        </div>
      </div>
    </div>

     <div className='footer-staff-management'>
     <Footer />
     </div>

     
    </>
  );
}

export default StaffManagement;

