import create from 'zustand';

import { enquiryService } from '../services/enquiry';

// Define initial state
const initialState = {
  forms: [],
  selectedForm: null,
  error: null,
  isLoading: false,
};

// Define store
const useEnquiryStore = create((set) => ({
  // State
  ...initialState,

  // Actions
  addForm: async (newForm) => {
    try {
      const data = await enquiryService.addForm(newForm);
      set((state) => ({ forms: [...state.forms, data], error: null }));
    } catch (error) {
      set({ error: error.message });
    }
  },

  updateForm: async (formId, updatedForm) => {
    try {
      await enquiryService.updateForm(formId, updatedForm);
      set((state) => ({
        forms: state.forms.map((form) =>
          form.id === formId ? { ...form, ...updatedForm } : form
        ),
        error: null,
      }));
    } catch (error) {
      set({ error: error.message });
    }
  },

  getForms: async () => {
    try {
      const data = await enquiryService.getForms();
      set({ forms: data, error: null });
    } catch (error) {
      set({ error: error.message });
    }
  },

  getFormById: async (formId) => {
    try {
      const data = await enquiryService.getFormById(formId);
      set({ selectedForm: data, error: null });
    } catch (error) {
      set({ error: error.message });
    }
  },

  deleteFormById: async (formId) => {
    try {
      await enquiryService.deleteFormById(formId);
      set((state) => ({
        forms: state.forms.filter((form) => form.id !== formId),
        error: null,
      }));
    } catch (error) {
      set({ error: error.message });
    }
  },

  // Reset error
  clearError: () => set({ error: null }),

  // Set loading state
  setLoading: (isLoading) => set({ isLoading }),

  // Reset store to initial state
  resetStore: () => set({ ...initialState }),
}));

export default useEnquiryStore;
