import React from 'react';
import ReactDOM from 'react-dom';
import StaffManagement from './Pages/StaffManagement';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import App from './App';
import AboutUs from './Pages/AboutUs';
import Admin from './Pages/Admin';
import Login from './Pages/Login';
import Career from './Pages/Careers';
import Clinical from './Pages/Clinical';
import Finance from './Pages/Finance';
import ContactUs from './Pages/Contactus';
import WhyUs from './Pages/Why';
import HospitalServices from './Pages/HospitalServices';
import Directorate from './Pages/Directorate';
import BlogTextEditor from './Pages/BlogTextEditor';
import News from './Pages/News';
import BlogDetails from './Pages/BlogDetails';

ReactDOM.render(
  <Router>
    <Routes>
        <Route path ='/'element ={ <App />}/>
        <Route path='StaffManagement' element = {<StaffManagement/>}/>
        <Route path='AboutUs' element = {<AboutUs/>}/>
        <Route path='Admin' element={<Admin/>}/>
        <Route path='Login' element={<Login/>}/>
        <Route path='Career' element={<Career/>}/>
        <Route path='Clinical' element={<Clinical/>}/>
        <Route path='Finance' element={<Finance/>}/>
        <Route path='ContactUs' element={<ContactUs/>}/>
        <Route path='WhUs' element={<WhyUs/>}/>
        <Route path='HospitalServices' element={<HospitalServices/>}/>
        <Route path='Directorate' element={<Directorate/>}/>
        <Route path='BlogTextEditor' element={<BlogTextEditor/>}/>
        <Route path='News' element={<News/>}/>
        <Route path="/BlogDetails/:id" element={<BlogDetails />} />
    </Routes>
  </Router>,

  document.getElementById('root')
);