import React from 'react';
import { HiOutlineMapPin } from "react-icons/hi2";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaLinkedinIn } from "react-icons/fa";
import './header.css';

function Header() {
  return (
    <div className='Header-header-social' style={{
      background: "#00A859",
      textAlign: "center",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px",
    }}>
      <div className='danwanua'>
        <p style={{
          fontFamily: "Karla",
          fontSize: "16px",
          fontWeight: "500",
          color: "white",
          marginRight: "20px",
        }}>
          <HiOutlineMapPin style={{ marginRight: "8px" }} /> Dawanau, Katsina Road, Kano State
        </p>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a href="https://www.facebook.com/p/Federal-Neuro-Psychiatric-Hospital-Dawanau-Kano-State-100095334090451/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px', fontSize: '20px' }}>
            <FaFacebook style={{ color: 'white' }} />
          </a>
          <a href="https://twitter.com/your-twitter-page" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px', fontSize: '20px' }}>
            <FaTwitter style={{ color: 'white' }} />
          </a>
          <a href="https://instagram.com/your-instagram-page" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px', fontSize: '20px' }}>
            <FaInstagram style={{ color: 'white' }} />
          </a>
          <a href="https://youtube.com/your-youtube-channel" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px', fontSize: '20px' }}>
            <FaYoutube style={{ color: 'white' }} />
          </a>
          <a href="https://linkedin.com/your-linkedin-page" target="_blank" rel="noopener noreferrer" style={{ fontSize: '20px' }}>
            <FaLinkedinIn style={{ color: 'white' }} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;