import React,{useState} from 'react'
import { handleApiSuccess } from '../APIs/helpers/success-message';
import { handleApiErrors } from '../APIs/helpers/handleApi-errors';
import { newsLetterService } from '../APIs/services/newsLetter';
import { FiSearch } from "react-icons/fi";


const BlogDetailsNewsLetter = () => {
    const [email, setEmail] = useState('');
    
  const handleSubmit = async (e) => {
  
    e.preventDefault();

    try {
      // Call the addSubscriber function from newsLetterService
      await newsLetterService.addSubscriber({ email });

      // Display success message
      handleApiSuccess('Thanks for subscribing ');

      // Clear the form fields after successful submission
      setEmail('');
    } catch (err) {
      // Handle errors
      handleApiErrors(err);
    }
  };

  return (
    <div>
            <h1 className='news-letter-details-blog'>NewsLetter</h1>
            <p className='news-letter-details-subscribe'>subscribe to our news letter</p>
           
            <form onSubmit={handleSubmit} className='newsletter-details'>
              <div className="newsletter-input-container-details">
                <input
                  type='email'
                  placeholder='Enter your email'
                  className='newsletter-input'
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <button className='newsletter-btn-details'><FiSearch /></button>
              </div>
            </form>

          </div>
  )
}

export default BlogDetailsNewsLetter
