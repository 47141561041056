import React from 'react';
import { TbAmbulance } from 'react-icons/tb';
import Director from '../Assets/md.jpg';
import './HeadFinance.css'; // Import the CSS file
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const HeadFinance = () => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="doctor-profile-container"
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
      transition={{ duration: 0.5 }}
    >
      <div className="doctor-container">
        <div className="left-content-finance">
          <div className="doctor-image-wrapper">
            <img src={Director} alt="Doctor" className="doctor-image-finance" />
          </div>
          <div className="doctor-info-medical"></div>
        </div>

        <div className="right-content-finance">
          <div className="bullet-list-admin">
            <h1 className="finance-info">
              Abdullahi Abba Kyaudai
              <br />
              <span class="medical-director"></span>
            </h1>

            <li>
              <div className="circle-about">
                <TbAmbulance className="icon" />
              </div>
              <span className="title">
                <span className="bold-text">Academic Journey</span>
                <br />
                Abdullahi Abba Kyaudai pursued his academic journey in Accounting, earning a Higher National Diploma
                (HND) from Kaduna State Polytechnic, followed by a Post Graduate Diploma in Accountancy (PGD) and a
                Master's Degree in Business Administration (MBA) from Ahmadu Bello University, Zaria, in 2005, 2007,
                and 2010 respectively. He is a distinguished Professional Accountant, holding membership in three
                prestigious professional bodies: Fellow of Certified National Accountants (CNA), Fellow of Chartered
                Institute of Management (CIM), and Institute of Certified Public Accountants of Nigeria (ICPAN).
              </span>
            </li>

            <li>
              <div className="circle-about">
                <TbAmbulance className="icon" />
              </div>
              <span className="title">
                <span className="bold-text">Brief Service Journey</span>
                <br />
                Abdullahi Abba Kyaudai has served in various capacities across Ministries, Departments, Agencies of
                Government (MDAs), NGOs, and international organizations. Notable roles include finance management
                positions in the Ministry of Health, Federal Ministry of Water Resources, Federal Ministry of Health,
                African Union Commission, and KNCV-Tuberculosis Foundation. Presently, he serves as the Head of
                Finance & Accounts at the Federal Neuropsychiatric Hospital Dawanau, Kano.
              </span>
            </li>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeadFinance;
