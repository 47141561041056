import { toast } from 'react-toastify';

export const handleApiErrors = (error) => {
  if (error.response) {
    const { data } = error.response;

    if (data && data.status === 'Failed' && data.errors && data.errors.length > 0) {
      const errorMessages = data.errors.map(errorItem => {
        const { message } = errorItem;

        // Display a toast with the error message
        toast.error(message);

        // Return the error message for further handling if necessary
        return message;
      });

      // You can also return or log the error messages array for further handling if necessary
      return errorMessages.join('\n');
    }
  }

  console.error('An unexpected error occurred:', error);

  // Return a generic error message
  const genericErrorMessage = 'An unexpected error occurred. Please try again.';
  toast.error(genericErrorMessage);

  return genericErrorMessage;
};
