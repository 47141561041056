






// BlogEditor.js
import React, { useState } from 'react';
import './BlogTextEditor.css';
import useBlogStore from '../APIs/stores/useBlog';
import { handleApiErrors } from '../APIs/helpers/handleApi-errors';
import { handleApiSuccess } from '../APIs/helpers/success-message';
import axios from 'axios'; // Import Axios for HTTP requests

const BlogTextEditor = () => {
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [image, setImage] = useState('');
    
    const [description, setDescription] = useState('');
    const { addBlog } = useBlogStore();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Upload image to Cloudinary
            const formData = new FormData();
            formData.append('file', image);
            formData.append('upload_preset', 'spmanagement'); // Replace 'your_cloudinary_upload_preset' with your actual upload preset
            const response = await axios.post('https://api.cloudinary.com/v1_1/spmanagementsystem/image/upload', formData);

            await addBlog({
                title,
                date,
                image: response.data.secure_url,
                description,
               
            });

            // Display success message
            handleApiSuccess('Blog post created successfully');

            // Clear the form fields after successful submission
            setTitle('');
            setDate('');
            setImage('');
            setDescription('');
        } catch (err) {
            // Handle errors
            handleApiErrors(err);
        }
    };

    return (
        <div className="blog-editor">
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Enter Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
               
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])} // Store the selected file
                />
                <textarea
                    placeholder="Write your blog here..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <button className='blog-text-editor' type="submit">Create Post</button>
            </form>
        </div>
    );
};

export default BlogTextEditor;
