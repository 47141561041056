import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { TbAmbulance } from 'react-icons/tb';
import Director from '../../Assets/director-move.png';
import './About.css'; // Import the CSS file
import { useInView } from "react-intersection-observer";

const DoctorProfile = () => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      if (currentPosition > (scrollDirection ?? 0)) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection]);

  return (
    <div  className="doctor-profile-container">
      <div className="doctor-container">
        <div className="left-content-medical">
          <div className="doctor-border">
            <img src={Director} alt="Doctor" className="director-image-medical" />
          </div>
          <div className="doctor-info-medical">
            <h1 className='DoctorTitle'> Dr. Auwal S. Salihu - Medical Director</h1>
          </div>
        </div>
        <div ref={ref} className="right-content-medical">
          <div className="bullet-list">
            <div className='Director'>
              <h1 className="doctor-title">A word from the Medical Director</h1>
            </div>
            <motion.div
              className={`medicat-director-word-title ${scrollDirection === 'down' ? ' animate__animated animate__bounceIn animate__slower' : ''}`}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <span>We set new standards in Psychiatric Care and General Medical Services</span>
            </motion.div>

            <motion.li
              className='animate__animated animate__bounceIn animate__slower'
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="circle-about-medical">
                <TbAmbulance className="icon-medical" />
              </div>
              <span className="title">
                <span className="bold-text-medical">Psychiatric Intensive Treatment</span><br />
                Our Psychiatric Care is thoughtfully structured to provide both
                effective short-term interventions and comprehensive long-term support.
              </span>
            </motion.li>

            <motion.li
              className='animate__animated animate__slideInRight animate__slower'
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="circle-about-medical">
                <TbAmbulance className="icon-medical" />
              </div>
              <span className="title">
                <span className="bold-text-medical">Specialized Support & Therapeutic Services</span><br />
                Our strategically located Occupational Therapy and Psychology units stand as
                cornerstone pillars in our comprehensive approach to rehabilitation and
                integration. These specialized departments are finely tuned to provide
                personalized support, employing evidence-based practices and innovative
                techniques to empower individuals on their journey towards improved
                well-being and successful integration into daily life
              </span>
            </motion.li>
            <motion.li
              className='animate__animated animate__slideInLeft animate__slower'
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="circle-about-medical">
                <TbAmbulance className="icon-medical" />
              </div>
              <span className="title">
                <span className="bold-text-medical">General Medical Services</span><br />
                The Federal Neuropsychiatric Hospital Dawanau Kano now extends its
                exceptional standards to encompass top-quality General Medical
                Services as well. Similarly, the Federal Neuropsychiatric
                Hospital Dawanau Kano is proud to offer a comprehensive
                range of General Medical Services, further enhancing its
                commitment to holistic patient care.
              </span>
            </motion.li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
