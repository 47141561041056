import React from 'react';
import './Consultation.css';
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdBed } from "react-icons/io";
import { TbEmergencyBed } from "react-icons/tb";
import { MdLocalPharmacy } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import { TbAmbulance } from 'react-icons/tb'; // Assuming 'TbAmbulance' is the correct import for your icon

const Consultation = () => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when 50% of the div is visible
  });
  
  return (
    <div  ref={ref}  className="consultation-container">
      <div className="left-section">
        <div className="Consultaion">Consultation</div>
        
        <div className="sub-title">Routine Consultation</div>
        <div className='consult-body-contents'>
        <AnimatePresence>
          <motion.div

initial={{ x: "-100vh", opacity: 0 }}
animate={{ x: "0", opacity: inView ? 1 : 0 }}
transition={{ duration: 1.5, delay: 0.1 }}
exit={{ x: "-100vh", opacity: 0 }}
          
          >
        <p className='ConsultationParams'>
        The consultation routine at the Federal NeuroPsychiatric
         Hospital Dawanau Kano encompasses a comprehensive approach to 
         evaluating and managing a range of neurological, 
         psychiatric, and psychological conditions. 
        
        </p>
        <p className='ConsultationParams'>
        The process is tailored to suit the diverse needs 
        of patients and may involve the following steps:<br/>
        Appointment Scheduling: Patients or their
         caregivers schedule appointments through 
         various channels such as phone, in-person,
          or online portals.

        </p>
        </motion.div>
        </AnimatePresence>
        </div>
       

        <button href='' className='ConsultationButton'>Let's Know More <FaLongArrowAltRight  /></button>
      </div>
      <div className="right-section">
        <div className="cards">
          <div className="circle">
            <TbAmbulance className="icon" />
          </div>
          <div className="CardContent">
            Monday-Friday<br /><p>24 Hours</p>
          </div>
        </div>
        <div className="cards">
          <div className="circle">
            <MdLocalPharmacy className="icon" />
          </div>
          <div className="CardContent">
            Pharmacy<br /><p>24 Hours</p>
          </div>
        </div>
        <div className="cards">
          <div className="circle">
            <IoMdBed className="icon" />
          </div>
          <div className="CardContent">
            Laboratory<br /><p>24 Hours</p>
          </div>
        </div>
        <div className="cards">
          <div className="circle">
            <TbEmergencyBed className="icon" />
          </div>
          <div className="CardContent">
            Emergency<br /><p>24 Hours</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultation;