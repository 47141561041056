
import create from 'zustand';
import { blogService } from '../services/blog';

// Define initial state
const initialState = {
  blogList: [],
  selectedBlog: null,
  error: null,
  isLoading: false,
};

// Define store
const useBlogStore = create((set) => ({
  // State
  ...initialState,

  // Actions
  addBlog: async (newBlog) => {
    try {
      const data = await blogService.addBlog(newBlog);
      set((state) => ({ blogList: [...state.blogList, data], error: null }));
    } catch (error) {
      set({ error: error.message });
    }
  },

  updateBlog: async (blogId, updatedBlog) => {
    try {
      await blogService.updateBlog(blogId, updatedBlog);
      set((state) => ({
        blogList: state.blogList.map((blog) =>
          blog.id === blogId ? { ...blog, ...updatedBlog } : blog
        ),
        error: null,
      }));
    } catch (error) {
      set({ error: error.message });
    }
  },

  getBlogs: async () => {
    try {
      const data = await blogService.getBlogs();
      set({ blogList: data, error: null });
    } catch (error) {
      set({ error: error.message });
    }
  },

  getBlogById: async (blogId) => {
    try {
      const data = await blogService.getBlogById(blogId);
      set({ selectedBlog: data, error: null });
    } catch (error) {
      set({ error: error.message });
    }
  },

  deleteBlogById: async (blogId) => {
    try {
      await blogService.deleteBlogById(blogId);
      set((state) => ({
        blogList: state.blogList.filter((blog) => blog.id !== blogId),
        error: null,
      }));
    } catch (error) {
      set({ error: error.message });
    }
  },

  // Reset error
  clearError: () => set({ error: null }),

  // Set loading state
  setLoading: (isLoading) => set({ isLoading }),

  // Reset store to initial state
  resetStore: () => set({ ...initialState }),
}));

export default useBlogStore;
