import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Home from '../../Assets/smart.png';
import Director2 from '../../Assets/another-free.png';
import Fed from '../../Assets/fedce.jpg'
import Medical from '../../Assets/Medical director.png'
import { FaLongArrowAltRight } from "react-icons/fa";
import Modal from 'react-modal'
import { TextField, Button, Container, Grid } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";



import './Home.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'animate.css';

export default function App() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const handleSlideChange = (swiper) => {
    setSlideIndex(swiper.activeIndex);
  };


  const variantColor = "#00A859"; // Desired variant color
  return (
    <div className='Home-wiper'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        onSlideChange={handleSlideChange}
        autoplay={{
          delay: 12000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="slider-container-home slide1">
          <div className="left-content animate__animated animate__slideInLeft animate__slower">

            <img src={Home} alt="home" className="transparent-image" />
          </div>
          <div className="right-content animate__animated animate__slideInRight animate__slower">
            <div className="text-container">
              <h1 className="text-container-header">
                Healing minds, Restoring Lives
              </h1>
              <p>
                Explore further, Schedule an appointment to gain  insights.
              </p>
              <button className="btn" onClick={() => setModalIsOpen(true)}>
                Book Appointment <FaLongArrowAltRight />
              </button>
            </div>
          </div>
        </SwiperSlide>


        <SwiperSlide className="slider-container-home slide3">
          <div className="left-content animate__animated animate__slideInLeft animate__slower">
            <div class="circle-home"></div>
            <img src={Director2} alt="director2" className="transparent-image" />
          </div>
          <div className="right-content animate__animated animate__slideInRight animate__slower">
            <div className="text-container">
              <h1 className="text-container-header">
                Exciting news! Soon, you'll have effortless access to our facilities right at your fingertips!
              </h1>
              <button className='federal-neuropyschiatric'>Federal Neuropsychiatric Hospital  Dawanau Celebrates </button>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider-container-home slide2">
          <div className="left-content animate__animated animate__slideInLeft animate__slower">
            <img src={Fed} alt="FedNeuro" className="transparent-image" />
          </div>
          <div className="right-content animate__animated animate__slideInRight animate__slower">
            <div className="text-container">
              <h1 className="text-container-header">
                Federal Neuropsychiatric Hospital Dawanau Kano Celebrates World Mental Health
              </h1>
              <button className='federal-neuropyschiatric'>Federal Neuropsychiatric Hospital  Dawanau Celebrates </button>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider-container-home slide2">
          <div className="left-content animate__animated animate__slideInLeft animate__slower">
            <img src={Medical} alt="FedNeuro" className="transparent-image" />
          </div>
          <div className="right-content animate__animated animate__slideInRight animate__slower">
            <div className="text-container">
              <h1 className="text-container-header">
                To provide evidence-based, quality, person-centred, mental healthcare, robust training and reliable research
              </h1>
              <button className='federal-neuropyschiatric'>Federal Neuropsychiatric Hospital  Dawanau Celebrates </button>
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2> <IoCloseOutline className='book-close-btn' onClick={() => setModalIsOpen(false)} /> Book Appointment: Coming soon</h2>
       



        <form className='modal-component'  >

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="firstName"
                name="firstName"
                label="Your Name"
                variant="standard"
                fullWidth

                margin="normal"


                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                name="lastName"
                label="Phone Number"
                variant="standard"
                fullWidth

                margin="normal"

                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                name="email"
                label="Address"
                variant="standard"
                fullWidth

                margin="normal"

                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="date"
                name="date"
                label="d"
                type="date" // Specify the input type as date
                variant="standard"
                fullWidth
                margin="normal"
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="name"
                name="name"
                label="Choose Doctor"
               // Specify the input type as date
                variant="standard"
                fullWidth
                margin="normal"
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="choose date"
                name="choose date"
                label="Choose Time"
                type="time" // Specify the input type as date
                variant="standard"
                fullWidth
                margin="normal"
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: `${variantColor} !important` } } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="Reason for appointment"
                variant="standard"
                multiline
                rows={4}
                fullWidth

                margin="normal"

                sx={{
                  '& .MuiOutlinedInput-root':
                  {
                    '& fieldset':
                      { borderColor: `${variantColor} !important` }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Button variant="contained"
            style={{
              backgroundColor: '#00A859'
              , gap: '8px', lineHeight: '24px', fontWeight: 500,
              height: '46px', color: '#FFFFFF', marginTop: '30px',
              padding: '8px, 24px, 8px, 24px', borderRadius: '4px'
            }}>
            Book Appointment
          </Button>

        </form>


      </Modal>
      

    </div>
  );
}







